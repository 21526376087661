import { createSlice } from "@reduxjs/toolkit";

// Reduc Storage 
export const AuthSlice = createSlice({
	name: "auth",
	initialState: {
		auth: localStorage.getItem("encore-auth-token") === null ? false : true,
		user: {},
		admin: {},
		contact: "",
		token: localStorage.getItem("encore-auth-token") || null,
		userFetched: false,
	},
	reducers: {
		// Use to store user daa on login
		login: (state, action) => {
			state.auth = true;
			state.token = action.payload.data.access_token;
			state.user = action.payload.data;
			localStorage.setItem("encore-auth-token", action.payload.data.access_token);
			localStorage.setItem("encore-user-ssn", action.payload.data.userSSN);
		},

		// user to store user data on refresh or user update
		getUserInfoAction: (state, action) => {
			state.userFetched = true;
			state.user = action.payload.data;
		},

		// Use to store user signup data
		signUpVerified: (state, action) => {
			state.auth = true;
			state.token = action.payload.access_token;
			state.user = action.payload;
			// localStorage.setItem("encore-auth-token", action.payload.access_token);
		},

		// Use to store user detail on forgot password flow page
		forgotVerify: (state, action) => {
			state.user = action.payload;
		},

		// Use to store admin detail on forgot password flow page
		adminForgotVerify: (state, action) => {
			state.admin = action.payload;
		},

		// Use to update access token on forgot password flow page
		forgotVerifyToken: (state, action) => {
			state.user.access_token = action.payload;
			localStorage.setItem("encore-auth-token", action.payload.access_token);
		},

		// Use to update user profile picture on change profile pic
		updateProfilePic: (state, action) => {
			state.user.userProfilePic = action.payload;
		},

		// user to reset redux state on logout
		logout: (state) => {
			state.auth = false;
			state.user = null;
			state.token = null;
			state.userFetched = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const { login, logout, signUpVerified, getUserInfoAction, forgotVerify, forgotVerifyToken, updateProfilePic, adminForgotVerify } = AuthSlice.actions;

export default AuthSlice.reducer;