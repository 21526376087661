import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { additionalFacilityDetails } from "../axios/apicall";
import Loader from "../components/Loader";

// Additional Facility Details
const AdditionalFacilityDetail = () => {
  let { id } = useParams();
  const [facilityDetail, setFacilityDetail] = useState();
  const [showLoader, setShowLoader] = useState(false);

  const getFacilityDetails = () => {
    if (id) {
      setShowLoader(true);
      additionalFacilityDetails(id)
        .then((res) => {
          if (res.data.status === "failure") {
            toast(res.data.message, { toastId: "additionalFacilitiesError" });
            setShowLoader(false);
          }
          if (res.data.status === "success") {
            setFacilityDetail(res.data.data);
            setShowLoader(false);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message, {
            toastId: "additionalFacilitiesFail",
          });
          setShowLoader(false);
        });
    } else {
      additionalFacilityDetails(1)
        .then((res) => {
          if (res.data.status === "failure") {
            toast(res.data.message, { toastId: "additionalFacilities1Error" });
            setShowLoader(false);
          }
          if (res.data.status === "success") {
            setFacilityDetail(res.data.data);
            setShowLoader(false);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message, {
            toastId: "additionalFacilities1Fail",
          });
          setShowLoader(false);
        });
    }
  };

  useEffect(() => {
    getFacilityDetails(id);
  }, [id]);

  document.title = "Encore | Additional Benefits";

  return (
    <>
      <Loader show={showLoader} />

      <div className="facility-detail-wrapper">
        {facilityDetail && (
          <div>
            <div className="facility-logo">
              <img src={facilityDetail.logoUrl} alt={facilityDetail.title} />
            </div>
            <div className="facility-details">
              {!facilityDetail.mediaUrl && (
                <div
                  className="facility-details-content"
                  dangerouslySetInnerHTML={{
                    __html: facilityDetail.description,
                  }}
                ></div>
              )}
              {facilityDetail.mediaUrl && (
                <div className="row">
                  <div
                    className="facility-details-content col-half"
                    dangerouslySetInnerHTML={{
                      __html: facilityDetail.description,
                    }}
                  ></div>
                  <div className="facility-details-column col-half">
                    <img
                      src={facilityDetail.mediaUrl}
                      alt={facilityDetail.title}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdditionalFacilityDetail;
