import React from "react";
import App from "./App";
import { Provider } from "react-redux";
import store from "./app/store";
import { createRoot } from "react-dom/client";
import CONFIG from "./config/api.config";
import axios from "axios";
import { toast } from "react-toastify";
// import { refreshToken } from "./axios/apicall";

axios.defaults.baseURL = CONFIG.axiosBaseUrl;
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			toast.error("Your Session is expired, please login again.", { toastId: "catchToast" });
			setTimeout(() => {
				window.location.href = "/login";
			}, 1000);

			// refreshToken(localStorage.getItem("encore-user-ssn"))
			// 	.then((res) => {
			// 		if (res.data.status === "failure") {
			// 			toast.error(res.data.message, {toastId:"errorToast"});
			// 			setTimeout(() => {
			// 				window.location.href = "/login";
			// 			}, 5000);
			// 		}
			// 		if (res.data.status === "success") {
			// 			localStorage.setItem("encore-auth-token", res.data.data.access_token)
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		toast.error("Your Session is expired, please login again.", {toastId: "catchToast"});
			// 		setTimeout(() => {
			// 			window.location.href = "/login";
			// 		}, 5000);
			// 	});
		}
		return error;
	}
);
const root = createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);
