import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/SCSS/pages/profile.scss";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import NoData from "../components/NoData";

const FamilyMembers = () => {
  const relativesDetail = useSelector((state) => state.auth.user.relatives);
  const MembersList = relativesDetail;
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(false);
  }, []);

  document.title = "Encore | Family Members";

  return (
    <main className="page">
      <div className="page-header">
        <div className="page-header-left">
          <h3 className="mb-0 d-flex align-center text-primary">
            Family Members
          </h3>
        </div>
      </div>
      <div className="row align-start">
        <div className="content full">
          <div className="content-box">
            <Loader show={showLoader} />
            {/* <h5 className="content-title">Family Members</h5> */}
            {MembersList?.length ? (
              <div className="card-grid">
                {MembersList.map((member) => {
                  return (
                    <div className="card-grid-item" key={member.userSSN}>
                      <div className="member-card">
                        <span className="member-sn">
                          {`${member.userFirstName.charAt(
                            0
                          )}${member.userLastName.charAt(0)}`}
                          {/* <img src={`${CONFIG.siteUrl}${member.userProfilePic}`} alt="" /> */}
                        </span>
                        <div className="member-detail">
                          <div className="member-name">{`${member.userFirstName} ${member.userLastName}`}</div>
                          <ul className="member-detail">
                            {/* <li>
												<strong>SSN: </strong>
												{member.userSSN}
											</li> */}
                            <li>
                              {member.userContact ? (
                                <>
                                  <strong>Contact: </strong>
                                  {member.userContact}
                                </>
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </li>
                          </ul>
                          <span className="text-success fw-500">
                            {member.userRealation}
                          </span>
                          <Link
                            to={`/profile/spouse-plan/${member.id}`}
                            className="btn sm outline"
                          >
                            View Plan
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default FamilyMembers;
