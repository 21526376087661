import React from "react";
import "../assets/SCSS/components/_loader.scss";

// Common Loader Component
const Loader = ({ show }) => {
	return (
		<div className={`page-loader ${show == true ? "show" : ""}`}>
			<span></span>
		</div>
	);
};

export default Loader;
