
import React from "react";
import "../assets/SCSS/pages/privacy_policy.scss";

// Privacy Policy Page
const PrivacyPolicyWithoutLogin = () => {

    document.title = "Encore | Privacy Policy";

    return (
        <>
            <header className="site-header p-header">
                <div className="container">
                    <a href="/employees">
                        <img className="site-logo" src="/static/media/encore.3d86763f7b4595a959bd7ea7b271af36.svg" alt="Encore" />
                    </a>
                </div>
            </header>
            <main className="page privacy-policy-page">
                <div className="page-header">
                    <div className="page-header-left">
                        <h3 className="mb-0 d-flex align-center">Privacy Policy for Encore Healthcare App</h3>
                    </div>
                </div>
                <div className="row align-start">
                    <div className="content full">
                        <div className="content-box">
                            <h5>Introduction</h5>
                            <p>Welcome to the Encore Healthcare App! This privacy policy explains how we collect, use, and
                                protect your personal information when you use our app. By using the app, you agree to the
                                terms outlined in this policy.</p>
                            <h5>Information We Collect</h5>
                            <ol>
                                <li>
                                    <strong>Registration Data</strong>
                                    <ul>
                                        <li>
                                            When you register for the Encore Healthcare App, we collect the following
                                            information:
                                            <ul>
                                                <li>
                                                    <strong>Phone Number:</strong> We use your phone number for account verification and
                                                    communication purposes.
                                                </li>
                                                <li>
                                                    <strong>Social Security Number (SSN):</strong>We collect your SSN for identity
                                                    verification during registration.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Usage Data</strong>
                                    <ul>
                                        <li>
                                            We may collect additional information as you use the app, including:
                                            <ul>
                                                <li>
                                                    <strong>App Usage:</strong> We track how you interact with the app, such as the features
                                                    you use, pages visited, and time spent.
                                                </li>
                                                <li>
                                                    <strong>Device Information:</strong>We collect device-specific information (e.g., device
                                                    model, operating system, and browser type) to improve app performance and troubleshoot issues.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <h5>How We Use Your Information</h5>
                            <ol>
                                <li>
                                    <strong>Account Management</strong>
                                    <ul>
                                        <li>
                                            We use your phone number and SSN for account creation, authentication, and
                                            security purposes.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Communication</strong>
                                    <ul>
                                        <li>
                                            We may use your phone number to send important notifications related to your
                                            account, such as password resets or account updates.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Analytics</strong>
                                    <ul>
                                        <li>
                                            We analyze usage data to improve our app, enhance user experience, and
                                            identify areas for optimization.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <h5>Sharing Your Information</h5>
                            <ul>
                                <li>
                                    We do not sell, rent, or share your personal information with third parties unless required
                                    by law or for app functionality (e.g., sharing data with healthcare providers).
                                </li>
                            </ul>
                            <h5>Your Choices</h5>
                            <ul>
                                <li>
                                    You can update your phone number in the app settings. If you have any concerns about
                                    your data, please contact our support team.
                                </li>
                            </ul>
                            <h5>Data Retention and Deletion Policy</h5>
                            <p>At Encore Healthcare, we take data privacy seriously. This policy outlines how we handle data
                                retention and deletion to ensure the security and privacy of our users.</p>
                            <h6>Data Retention</h6>
                            <ol>
                                <li>
                                    <strong>User Account Data:</strong>
                                    <ul>
                                        <li>
                                            We retain user account data for as long as the user actively uses the Encore
                                            Healthcare App.
                                        </li>
                                        <li>
                                            If a user deletes their account, we retain their data for a period of 30 days to
                                            allow for account recovery.
                                        </li>
                                        <li>After 30 days, all user account data is permanently deleted from our systems.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Usage Analytics:</strong>
                                    <ul>
                                        <li>
                                            We collect anonymized usage data for app analytics.
                                        </li>
                                        <li>
                                            Usage data is retained for up to 24 months to improve app performance and user
                                            experience.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <h6>Data Deletion</h6>
                            <ol>
                                <li>
                                    <strong>User-Requested Deletion:</strong>
                                    <ul>
                                        <li>
                                            Users can request deletion of their account and associated data at any time.
                                        </li>
                                        <li>
                                            Upon receiving a deletion request, we initiate the process within 48 hours.
                                        </li>
                                        <li>Users can delete their account manually by visiting the My Profile screen of the
                                            app.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Automated Deletion:</strong>
                                    <ul>
                                        <li>
                                            After the retention period expires, we automatically delete user records and other
                                            data.
                                        </li>
                                        <li>
                                            Regular audits ensure compliance with our data retention policies.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <h5>Security Measures</h5>
                            <ol>
                                <li>
                                    <strong>Encryption:</strong>
                                    <ul>
                                        <li>
                                            All data is encrypted both in transit and at rest.
                                        </li>
                                        <li>
                                            We use industry-standard encryption algorithms to protect user information.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Access Controls:</strong>
                                    <ul>
                                        <li>
                                            Access to user data is restricted to authorized personnel only.
                                        </li>
                                        <li>
                                            Employees undergo regular security training to maintain data confidentiality.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Data Backup and Disaster Recovery:</strong>
                                    <ul>
                                        <li>
                                            We maintain secure backups to prevent data loss.
                                        </li>
                                        <li>
                                            Disaster recovery procedures ensure data availability even in unforeseen events.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <h5>Changes to this Policy</h5>
                            <ul>
                                <li>
                                    We may update this privacy policy from time to time. Check back periodically for any
                                    changes.
                                </li>
                            </ul>
                            <h5>Developer Information</h5>
                            <ul>
                                <li>
                                    <strong>Developer Name: </strong>We may update this privacy policy from time to time. Check back periodically for any
                                    changes.
                                </li>
                                <li>
                                    <strong>Support URL: </strong>https://encorehr.zendesk.com/hc/en-us
                                </li>
                                <li>
                                    <strong>Privacy Point of Contact: </strong>Our privacy team can be reached at the above support URL.
                                </li>
                            </ul>
                            <h5>Contact Us</h5>
                            <p>If you have questions or need assistance, please contact our support team at
                                https://encorehr.zendesk.com/hc/en-us</p>
                        </div>
                    </div>
                </div>
            </main></>
    );
};

export default PrivacyPolicyWithoutLogin;
