import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";

// Firebase Configurations
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_apiKey,
	authDomain: process.env.REACT_APP_FIREBASE_authDomain,
	projectId: process.env.REACT_APP_FIREBASE_projectId,
	storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
	messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
	appId: process.env.REACT_APP_FIREBASE_appId,
	measurementId: process.env.REACT_APP_FIREBASE_measurementId,
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);
// onMessage(messaging, (payload) => {
// 	console.log("Message received. ", payload);
// });

// Fetch Firebase device token
export const fetchToken = (setTokenFound) => {
	return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_vapidKey })
		.then((currentToken) => {
			if (currentToken) {
				localStorage.setItem("encore-device-token", currentToken);
				setTokenFound(true);
			} else {
				console.log("No registration token available. Request permission to generate one.");
				setTokenFound(false);
			}
		})
		.catch((err) => {
			const errorMessage = err.message;
			if (!localStorage.getItem("encore-admin")) {
				// toast.error(errorMessage, { toastId: "fireBaseTokenError" });
				console.log("Firebase Access Error =>", errorMessage);
			}
			// console.log("An error occurred while retrieving token. ", err);
		});
};
