import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import CONFIG from "../config/api.config";
import "../assets/SCSS/pages/profile.scss";

// Privacy Policy Page
const PrivacyPolicy = () => {
	const [policy, setPolicy] = useState("");

	axios
		.post(
			CONFIG.privacyPolicy,
			{
				key: "policy_site",
			},
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
					"Content-Type": "application/json",
				},
			}
		)
		.then((res) => {
			setPolicy(res.data.data.content);
		})
		.catch((err) => {
			toast.error(err.response?.data?.message, { toastId: "policyErrror" });
		});

	document.title = "Encore | Privacy Policy";
	
	return (
		<main className="page">
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 d-flex align-center">Privacy Policy</h3>
				</div>
			</div>
			<div className="row align-start">
				<div className="content full">
					<div className="content-box">
						<p>{policy}</p>
					</div>
				</div>
			</div>
		</main>
	);
};

export default PrivacyPolicy;
