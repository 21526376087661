import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CONFIG from "../config/api.config";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { getCommonConditionList } from "../axios/apicall";
import NoData from "../components/NoData";

// All Conditions Listing Page
const AllConditions = () => {
	const [dataOffset, setDataOffset] = useState(0);
	const [showLoader, setShowLoader] = useState(true);
	const [speciality, setSpeciality] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [page, setPage] = useState(1);
	let navigate = useNavigate();
	const defaultLimit = 24;

	const reqData = {
		offset: dataOffset,
		limit: defaultLimit * page,
		search: "",
	};

	const loadSpecialityList = (token) => {
		getCommonConditionList(reqData, token)
			.then((res) => {
				setTotalRecords(res.data.data.count);
				setSpeciality(res.data.data.specialities);
				setShowLoader(false);
			})
			.catch((err) => {
				toast.error(err.response?.data?.message);
			});
	};

	useEffect(() => {
		if (localStorage.getItem("encore-auth-token")) {
			loadSpecialityList(localStorage.getItem("encore-auth-token"));
		}
	}, [page]);

	const listscrollRef = useBottomScrollListener(() => {
		if (speciality?.length < totalRecords) {
			setPage(page + 1);
		}
	});
	
	document.title = "Encore | Conditions";
	
	return (
		<main className="page infinite" ref={listscrollRef}>
			<Loader show={showLoader} />
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 text-primary d-flex align-center">
						<i className="cursor-pointer text-body mr-1 icon-back" onClick={() => navigate(-1)}></i>
						Conditions
					</h3>
				</div>
			</div>
			{speciality?.length ? (
				<div className="care-list">
					{speciality?.map((care) => {
						return (
							<a key={care.id} href={CONFIG.redirectionURL} target="_blank">
								{care.name}
							</a>
						);
					})}
				</div>
			) : (
				<NoData />
				// <h4 className="text-center">No Data</h4>
			)}
		</main>
	);
};

export default AllConditions;
