import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getChatUserListing } from "../../axios/apicall";
import profilePic from "../../assets/images/user-profile.png";
import { toast } from "react-toastify";

import CONFIG from "../../config/api.config";
import io from 'socket.io-client';
import { CHAT_TYPE, SENDER_ID } from "./Chat";

const ChatSidebar = () => {
	const { id } = useParams();

	const [chatUsers, setChatUsers] = useState();
	const [allChatUsers, setallChatUsers] = useState();

	// Load users on page load
	const chatUserListing = () => {
		getChatUserListing().then((res) => {
			setChatUsers(res.data.data.data)
			setallChatUsers(res.data.data.data)
		}).catch((err) => {
			toast(err.response?.data?.message, { toastId: "chatUserListinError" });
		});
	}
	useEffect(() => {
		chatUserListing()
	}, [id]);
	useEffect(() => {
		chatUserListing()

		// configure socket
		let socket = io(CONFIG.socketURL, { query: `senderId=${SENDER_ID}&groupName=''` });
		socket.on('newChatconnect', (data) => { chatUserListing(); })
		socket.on("notifyUnreadGlobal", (data) => { chatUserListing(); })
	}, []);

	// User search 
	const searchChatUser = (e) => {
		e.preventDefault();
		if (e.target.value == "") {
			setChatUsers(allChatUsers)
		} else {
			setChatUsers(allChatUsers.filter((user) => {
				return (user.userName).toLowerCase().search(
					e.target.value.toLowerCase()) !== -1;
			}))
		}
	}

	return (
		<div className="chat-sidebar">
			<div className="chat-sidebar-header">
				<div className="chat-user-search">
					<input type="text" placeholder="Search" onChange={(e) => searchChatUser(e)} />
					<i className="icon-search"></i>
				</div>
			</div>
			<div className="chat-user-list">
				{chatUsers &&
					chatUsers?.map((user) => {
						return (
							<Link key={`userId-${user.userId}`} to={`/admin/chat/${user.userId}`} className={`chat-user ${user.userId == id ? "active" : ""}`}>
								<div className="user-avtar">
									<img src={user.profileImage ? user.profileImage : profilePic} alt="User" />
									<span className={`user-status ${user.online ? "online" : ""}`}></span>
								</div>
								<div className="user-detail">
									<div className="user-name">{user.userName}</div>
									{user.message && <small className="user-msg">{user.message}</small>}
								</div>
								{user.unread > 0 && <div className="user-message-count">{user.unread}</div>}
							</Link>
						);
					})}
			</div>
		</div>
	);
};

export default ChatSidebar;
