import React from "react";
import "../assets/SCSS/components/_lists.scss";

// Social media links common component
const SocialLinks = () => {
	const links = [
		{
			title: "Facebook",
			icon: "icon-facebook",
			link: "https://www.facebook.com/",
		},
		{
			title: "Youtube",
			icon: "icon-youtube",
			link: "https://www.youtube.com/",
		},
		{
			title: "LinkedIn",
			icon: "icon-linkedin",
			link: "https://www.linkedin.com/",
		},
	];
	return (
		links && (
			<div className="soclial-links">
				<ul>
					{links.map((link) => {
						return (
							<li key={`social-link-${link.title}`}>
								<a href={link.link} target="_blank" rel="noopener noreferrer" title={link.title}>
									<i className={link.icon}></i>
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		)
	);
};

export default SocialLinks;
