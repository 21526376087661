import { toast } from "react-toastify";
import { updateSpouseRequest } from "../../axios/apicall";

export const approveRejectReq = (approval, userSSN) => {
	updateSpouseRequest(approval, userSSN)
		.then((res) => {
			if (res.data.status === "failure") {
				toast.error(res.data.message, { toastId: "spouseRequestFail" });
			}
			if (res.data.status === "success") {
				toast.success(res.data.message, { toastId: "spouseRequestSuccess" });
			}
		})
		.catch((err) => {
			toast.error(err.response?.data?.message, { toastId: "spouseRequestFail" });
		});
};