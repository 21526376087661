import React, { useEffect, useState } from "react";
import "../assets/SCSS/components/_form-controls.scss";
import "../assets/SCSS/components/_button.scss";

// Common Form Controls Component
export const InputGroup = ({ name, label, icon, placeholder, type, error, success, value, disabled, setError, validate, autoFocus }) => {
	const [pwdType, setPwdType] = useState("password");
	const [inputValue, setinputValue] = useState(value || "");

	useEffect(() => {
		if (value) {
			setinputValue(value);
		}
	}, [value]);
	// Handle change and validate input
	const handleChangeValue = (e) => {
		let pattern;

		// validate Mobile Number
		if (validate == "mobile" && e.target.value.length < 11) {
			setinputValue(e.target.value);
			pattern = new RegExp(/^(\+91[\-\s]?)?[0]?(91)?[0123456789]\d{9}$/);
			return pattern.test(e.target.value) ? setError(false) : setError(true);
		}

		// validate Email
		if (validate == "email") {
			setinputValue(e.target.value);
			pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,3})+$/);
			return pattern.test(e.target.value) ? setError(false) : setError(true);
		}

		// Validate Password
		if (type == "password") {
			setinputValue(e.target.value);
			pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/);
			return pattern.test(e.target.value) ? setError(false) : setError(true);
		}

		// Validate SSN Number
		if (validate == "ssn" && e.target.value.length < 10) {
			setinputValue(e.target.value);
			pattern = new RegExp(/^\d[0-9]{8}$/);
			return pattern.test(e.target.value) ? setError(false) : setError(true);
		}

		// No validation
		if (!validate) {
			setinputValue(e.target.value);
		}
		// return setError(false);
	};
	return (
		<div className="form-group">
			{label && (
				<label className="form-label" htmlFor={`${type != "password" ? "input-mobile" : "input-icon"}`}>
					{label} 
				</label>
			)}
			<div className={`input-group ${validate == "mobile" ? "mobile" : ""}`}>
				{/* Render input Field */}
				{type != "password" ? (
					<>
						<input id="input-mobile" name={name} className={`form-control${icon ? "" : " no-icon"}${error == true ? " error" : ""}${success ? " success" : ""}`} type={type || "text"} placeholder={placeholder || "Placeholder Text"} value={inputValue} onChange={handleChangeValue} disabled={disabled} autoFocus={autoFocus == "true" ? true : false} />
						{validate == "mobile" && <span className="contry-code">{localStorage.getItem("encore-country-code") || "+1"}</span>}
					</>
				) : (
					<>
						{/* Render Password Field with Icon Hide / Show Password Field */}
						<span
							className="show-pass"
							onClick={() => {
								pwdType == "password" ? setPwdType("text") : setPwdType("password");
							}}>
							{pwdType == "password" ? <i className="icon-eye-cancel"></i> : <i className="icon-eye"></i>}
						</span>
						<input id="input-icon" name={name} className={`form-control${icon ? "" : " no-icon"}${error == true ? " error" : ""}${success ? " success" : ""}`} type={pwdType} placeholder={placeholder || "Placeholder Text"} value={inputValue} onChange={handleChangeValue} />
					</>
				)}
				{/* Add Input Icon */}
				{icon && (
					<span className="input-icon">
						<i className={`icon-${icon}`} />
					</span>
				)}
			</div>

			{/* {type == "password" && error && (
				<div className="error-info">
					Password Must be 6 charectors long, <br />
					Must contain a capital, lowercase, and number
				</div>
			)} */}
		</div>
	);
};

// Common Button Component
export const Button = (props) => {
	const { type, size, width, children } = props;
	return <button className={`btn ${type} ${width} ${size || "md"}`}>{children}</button>;
};


