import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../assets/SCSS/pages/auth.scss";
import pageBg from "../../assets/images/auth-bg.webp";
import mobileBg from "../../assets/images/auth-bg-mobile.webp";
import logo from "../../assets/images/encore.svg";
import OtpInput from "react18-input-otp";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import CONFIG from "../../config/api.config";
import { toast } from "react-toastify";
import { forgotVerifyToken } from "../../features/login/AuthSlice";

// Verification Code
const AdminVerificationCode = () => {
	const userDetail = useSelector((state) => state.auth);

	let navigate = useNavigate();
	const dispatch = useDispatch();

	const [error, seterror] = useState(false);
	const [otp, setotp] = useState("");
	const [loading, setLoading] = useState(false);

	const resetPass = () => {
		if (otp?.length < 4) {
			seterror(true);
		} else {
			seterror(false);
			setLoading(true);

			axios
				.post(
					CONFIG.adminVerifyOtp,
					{
						emailId: userDetail?.admin?.userEmail,
						userOtp: otp,
					},
					{
						headers: {
							Authorization: "Bearer " + userDetail?.admin?.access_token,
							// access_token: reqData.access_token,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					if (res.data.status === "failure") {
						toast.error(res.data.message, { toastId: "resetPasswordFail" });
						seterror(true);
						setLoading(false);
					}
					if (res.data.status === "success") {
						setLoading(false);
						seterror(false)
						toast.success(res.data.message, { toastId: "resetPasswordSuccess" });

						dispatch(forgotVerifyToken(res.data.data.access_token))
						navigate("/admin/create-new-password");
					}
				})
				.catch((err) => {
					toast.error(err.response?.data?.message, { toastId: "resetPasswordCatchError" });
					setLoading(false);
					navigate("/admin");
				});
		}
	};

	// Update OTP State Value
	const handleChange = (otpNumber) => {
		setotp(otpNumber);
	};

	document.title = "Encore | OTP Verification";

	return (
		<div className="auth-page-wrapper">
			<picture>
				<source media="(min-width:768px)" srcSet={pageBg} />
				<img src={mobileBg} alt="auth-page" height="970px" width="1920px" className="bg-img" />
			</picture>
			<div className="logo">
				<img src={logo} alt="Encore" height="40px" width="171px" />
			</div>
			<div className="auth-card ml-auto">
				<h4 className="card-title text-center">Verification Code</h4>
				<p className="text-center">
					Please Enter Verification OTP
				</p>
				<OtpInput value={otp} onChange={handleChange} numInputs={4} containerStyle="verification-digits" inputStyle="otp-digit" shouldAutoFocus="true" hasErrored={error} errorStyle="error" isInputNum="true" placeholder="0000" />

				<button className={`btn btn-primary full ${loading ? "loading" : ""}`} onClick={() => resetPass()}>
					Verify
				</button>
				<div className="text-center mt-3">
					<a
						href=""
						onClick={(e) => {
							e.preventDefault();
							navigate(-1);
						}}>
						Back
					</a>
				</div>
			</div>
		</div>
	);
};

export default AdminVerificationCode;
