import React, { useEffect, useState } from "react";
import { downloadSampleFile, importFile } from "../axios/apicall";
import Loader from "../components/Loader";
import "../assets/SCSS/pages/error-imported-employees.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PopupImportFile from "./PopupImportFile";

const ErrorImportedEmployees = () => {
  const Navigate = useNavigate();
  const [errorImportedUsers, setErrorImportedUsers] = useState([]);
  const [employeeCount, setEmployeeCount] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [search, setSearch] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileInput, setShowFileInput] = useState(false);
  const [modifiedData, setModifiedData] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [ignoredColumns, setIgnoredColumns] = useState([]);

  const [filter, setFilter] = useState({
    userStatus: null,
    search: null,
    page: 1,
  });

  useEffect(() => {
    setShowLoader(true);
    const errorImportedEmployees = JSON.parse(localStorage.getItem('error-imported-employees'));
    const ignoredEmployees = JSON.parse(localStorage.getItem('imported-ignored-records'));

    if (errorImportedEmployees) {
      setErrorImportedUsers(errorImportedEmployees);
      setEmployeeCount(errorImportedEmployees.length);
      setShowLoader(false);
    }
    if (ignoredEmployees) {
      setIgnoredColumns(ignoredEmployees);
      setShowLoader(false);
    }
  }, [filter, modifiedData]);

  document.title = "Imported Employees";

  const ShowPopupHandler = () => {
    setShowPopup(true);
  }
  const closePopUpHandler = () => {
    setShowPopup(false);
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (selectedFile) {

      const fileExtension = selectedFile.name.split('.').pop();

      if (fileExtension !== 'csv') {
        toast.error("Unsupported file format.");
        return false;
      }
      if (selectedFile.size > 100 * 1024 * 1024) { // 100 MB in bytes
        toast.error("File size exceeds the maximum allowed limit (100 MB).");
        return false;
      }
      setShowLoader(true);  
      const fileUrl = URL.createObjectURL(selectedFile);
      setSelectedFileUrl(fileUrl);

      const form_data = new FormData();
      form_data.append('userData', selectedFile);

      setShowLoader(true);

      importFile(form_data)
        .then((res) => {
          setShowLoader(false);
          setSelectedFile(null)
          if (res.data.status_code === 400) {
            toast.error(res.data.message);
            localStorage.setItem('error-imported-employees', JSON.stringify(res.data.data));
            setModifiedData(!modifiedData);
            setShowPopup(false);
            setShowLoader(false);
          }
          if (res.data.status_code === 200) {
            toast.success(res.data.message);
            setShowLoader(false);
            localStorage.setItem('imported-employees', JSON.stringify(res.data.data?.importedRecords));
            localStorage.setItem('imported-ignored-records', JSON.stringify(res.data.data.ignoredRecords));
            setModifiedData(!modifiedData);
            setShowPopup(false);
            Navigate(`/employees/imported`);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
          setShowLoader(false);
        });
    }
  };
  const DownloadFileHandler = () => {
    downloadSampleFile()
      .then((res) => {
        if (res.status === 200) {
          const sampleFileData = res.data; // This should contain your sample file data
          // Create a Blob object from the file data
          const blob = new Blob([sampleFileData], { type: "text/csv;charset=utf-8" });
          // Create a URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
          // Create an anchor element
          const anchor = document.createElement("a");
          anchor.href = blobUrl;
          anchor.download = "sample-file";
          // Programmatically click the anchor to trigger the download
          anchor.click();
          // Clean up the URL created for the Blob
          URL.revokeObjectURL(blobUrl);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setShowLoader(false);
      });
  }
  
  return (<>
    <main className="page">
      <div className="page-header page-header-imported">
        <div className="page-header-left">
        </div>
        <div className="form-filter-group">
          <div className="upload-sheet-btn">
            <button onClick={ShowPopupHandler}>Upload Employee Sheet</button>
          </div>
        </div>
      </div>
      <div className="row align-start">
        <div className="content full">
          <Loader show={showLoader} />
          <div className="table-wrapper">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th colSpan={2} className="bg-primary text-white">Summary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>No. of Records</td>
                  <td>{errorImportedUsers.noOfRecords}</td>
                </tr>
                
                <tr>
                  <td>Provided Columns</td>
                  <td>
                  {
                      errorImportedUsers?.providedColumns && errorImportedUsers?.providedColumns.length > 0 ? (
                        errorImportedUsers?.providedColumns?.map((data, key) => {
                          return (
                              <span key={key} className="badge badge-primary">{data}</span>
                          )
                        })
                      ): (
                        "-"
                      )
                   
                  }
                  </td>
                </tr>
                <tr>
                  <td>Invalid Columns</td>
                  <td>
                    {
                      errorImportedUsers?.invalidColumns && errorImportedUsers?.invalidColumns.length > 0 ?(
                        errorImportedUsers?.invalidColumns?.map((data, key) => {
                        return (
                            <span key={key} className="badge badge-primary">{data}</span>
                        )
                      })
                      ):(
                        '-'
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td>Missing Columns</td>
                  <td>
                    {
                      errorImportedUsers?.missingColumns &&  errorImportedUsers?.missingColumns.length > 0 ? (

                        errorImportedUsers?.missingColumns?.map((i, key) => {
                          return (
                              <span key={key} className="badge badge-primary">{i}</span>
                          )
                        })
                      ) : (
                        "-"
                      )
                    }
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="page-header-left">
              <h3 className="mb-2 mt-3 d-flex align-center text-primary">Invalid Records Details</h3>
            </div>
            <table className="table table-hover invalid-records-table">
              <thead>
                <tr>
                  <th className="bg-primary text-white">User Name</th>
                  <th className="bg-primary text-white">Email</th>
                  <th className="bg-primary text-white">Employee type</th>
                  <th className="bg-primary text-white">Plan detail</th>
                  <th className="bg-primary text-white">Error</th>
                </tr>
              </thead>
              <tbody>
                {
                  errorImportedUsers?.invalidRecords && errorImportedUsers?.invalidRecords.length > 0 ? (
                    errorImportedUsers?.invalidRecords?.map((importedData, key) => { 
                      return(
                        <tr key={key}>
                          <td>{importedData?.record['Covered Person']}</td>
                          <td>{importedData?.record['Email Address']}</td>
                          <td>{importedData?.record['Empl Type']}</td>
                          <td>{importedData?.record['Plan Name'] ? importedData?.record['Plan Name'] : " - "}</td>
                          <td className="text-left">
                            <ol>
                              {
                                importedData?.errors?.map((val, index) => {
                                  return (
                                    <li>{val}</li>
                                  )
                                })
                              }
                            </ol>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                        <td colSpan={5} className="no-data-found">No Data</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
         
        </div>
      </div>
      {/* </div> */}
    </main>

    <PopupImportFile
      showFileInput={showFileInput}
      showPopup={showPopup}
      closePopUpHandler={closePopUpHandler}
      handleFileChange={handleFileChange}
      handleFileUpload={handleFileUpload}
      selectedFile={selectedFile}
      setShowFileInput={setShowFileInput}
      DownloadFileHandler={DownloadFileHandler}
      showLoader={showLoader}
    />
  </>
  );
};

export default ErrorImportedEmployees;
