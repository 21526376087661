import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUserPlanDetails } from "../axios/apicall";
import Loader from "../components/Loader";
import NoData from "../components/NoData";
import "../assets/SCSS/pages/profile.scss";

// Profile Plan detail page
const MyPlans = () => {
	const [plans, setPlans] = useState(null);
	const [PlanClount, setPlanCount] = useState(1);
	const [showLoader, setShowLoader] = useState(true);
	const userDetail = useSelector((state) => state.auth);

	useEffect(() => {
		setShowLoader(false);
	}, []);

	useEffect(() => {
		getUserPlanDetails(userDetail?.user?.id)
			.then((res) => {
				if (res.data.status === "failure") {
					toast.error(res.data.message, { toastId: "planLoadingFail" });
				}
				if (res.data.status === "success") {
					if (res.data.data.length != 0) {
						setPlanCount(res.data.data[0].planDetails[0].option_headers.length);
						setPlans(res.data.data[0].planDetails);
					}
				}
			})
			.catch((err) => {
				toast.error(err.response?.data?.message, { toastId: "planLoadingcatcherror" });
			});
	}, [userDetail]);

	document.title = "Encore | My Plan";

	return (
		<main className="page">
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 d-flex align-center text-primary">My Plan</h3>
				</div>
			</div>
			<div className="row align-start">
				<div className="content full">
					<div className="content-box">
						<Loader show={showLoader} />
						{/* <h5 className="content-title">My Plan</h5> */}
						<div className="table-wrapper">
							{plans && plans?.length ? (
								plans.map((plan) => {
									return (
										<div className="plan-table-wrapper" key={"plan-table-" + Math.random()}>
											<table className="plan-table" key={`plan-table-${Math.random()}`}>
												{plan.header != null || plan.option_headers != null ? (
													<thead>
														{plan.option_headers != null ? (
															<tr className="plan-table-header">
																<th className="bg-secondary text-white"></th>
																{plan.option_headers.map((header) => {
																	return (
																		<th className="bg-primary text-white" key={`option-header-${Math.random()}`}>
																			{header.title}
																		</th>
																	);
																})}
															</tr>
														) : null}
														{plan.header != null ? (
															<tr>
																<th className="bg-primary text-white text-left">{plan.header}</th>
																<th className="bg-primary text-white" colSpan={PlanClount}></th>
															</tr>
														) : null}
													</thead>
												) : null}
												{plan?.result?.length ? (
													<tbody>
														{plan.result.map((row) => {
															return (
																<tr key={row.key}>
																	<td>{row.title}</td>
																	{row.datas?.map((data) => {
																		return <td key={`${data.value} ${Math.random()}`}>{data.value}</td>;
																	})}
																</tr>
															);
														})}
													</tbody>
												) : null}
											</table>
										</div>
									);
								})
							) : (
								<NoData />
							)}
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default MyPlans;
