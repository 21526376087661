import React, { useEffect, useState } from "react";
import { InputGroup } from "../../components/Form";
import { toast } from "react-toastify";
import axios from "axios";
import CONFIG from "../../config/api.config";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { adminLogout } from "../../features/logout/Logout";

const AdminUpdatePass = () => {
    let navigate = useNavigate();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

	if (!localStorage.getItem('encore-admin')) {
		navigate(-1, { replace: true })
	}

    useEffect(() => {
        setShowLoader(false);
    }, []);

    const updateUserPassword = (e) => {
        e.preventDefault();
        const userOldPassword = document.getElementsByName("old-password")[0].value;
        const userNewPassword = document.getElementsByName("new-password")[0].value;
        const userConfirmPassword = document.getElementsByName("confirm-password")[0].value;
        if (userNewPassword != userConfirmPassword) {
            toast.error("New Password and Confirm password not matched.");
            document.getElementsByName("confirm-password")[0].focus();
            setError(true);
            return;
        }
        if (!userOldPassword) {
            toast.error("Old Password Required.");
            setError(true);
            document.getElementsByName("old-password")[0].focus();
            return;
        }
        if (!userNewPassword) {
            toast.error("New Password Required.");
            setError(true);
            document.getElementsByName("new-password")[0].focus();
            return;
        }
        setLoading(true);
        axios
            .post(
                CONFIG.adminChangePassword,
                {
                    oldPassword: userOldPassword,
                    password: userNewPassword
                },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "failure") {
                    toast.error(res.data.message);
                    setError(true);
                    setLoading(false);
                }
                if (res.data.status === "success") {
                    setError(false);
                    toast.success(res.data.message);
                    setLoading(false);
                    // adminLogout(navigate("/admin"))
                }
            })
            .catch((err) => {
                toast.error(err.response?.data?.message);
                setLoading(false);
            });
    };

    document.title = "Encore | Change Passsword";

    return (
        <main className="page">
            <div className="page-header">
                <div className="page-header-left">
                    <h3 className="mb-0 d-flex align-center text-primary"><small className="cursor-pointer text-body mr-1 icon-back" onClick={() => navigate(-1)}></small> Change Password</h3>
                </div>
            </div>
            <div className="row align-start">
                <div className="content full">
                    <div className="content-box">
                        <Loader show={showLoader} />
                        {/* <h5 className="content-title">Change Password</h5> */}
                        <form onSubmit={updateUserPassword}>
                            <div className="row">
                                <div className="col-full">
                                    <div className="w-25">
                                        <InputGroup label="Old Password" placeholder="Old Password" type="password" name="old-password" error={error} setError={setError} />
                                    </div>
                                </div>
                                <div className="col-full">
                                    <div className="w-25">
                                        <InputGroup label="New Password" placeholder="New Password" type="password" name="new-password" error={error} setError={setError} />
                                    </div>
                                </div>
                                <div className="col-full">
                                    <div className="w-25">
                                        <InputGroup label="Confirm New Password" placeholder="Confirm New Password" type="password" error={error} name="confirm-password" setError={setError} />
                                    </div>
                                </div>
                                <div className="col-full mt-2 mb-3">
                                    <button className={`btn ${loading ? "loading" : ""}`} type="submit">
                                        Change Password
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AdminUpdatePass;
