import "../assets/SCSS/pages/errro-page.scss";
import errorImg from "../assets/images/No data-pana.svg";
import { Link } from "react-router-dom";

const ErrorPage = () => {
	document.title = "Encore | Error";
	
	return (
		<div className="error-page">
			<div className="error-card">
				<img src={errorImg} alt="Error" />
				<h1 className="mb-1">404</h1>
				<h5 className="mb-1">Oops!</h5>
				<p className="mb-1">Sorry, an unexpected error has occurred.</p>
				<Link to="/" className="btn btn-primary mt-3">
					Back To Home
				</Link>
			</div>
		</div>
	);
};
export default ErrorPage;
