import { toast } from "react-toastify";
import { logoutAdminUser, logoutUser } from "../../axios/apicall";


// Common Logout for Global use
export const logOut = (dispatch, navigate) => {
	if (localStorage.getItem("encore-auth-token")) {
		logoutUser()
			.then((res) => {
				if (res.data.status === "failure") {
					toast.error(res.data.message, { toastId: "logoutUserError" });
				}
				if (res.data.status === "success") {
					localStorage.removeItem("encore-auth-token");
					localStorage.removeItem("encore-device-token");
					localStorage.removeItem("encore-user-ssn");
					localStorage.removeItem("encore-admin")

					dispatch;
					toast.success(res.data.message, { toastId: "LogoutUserSuccess" });
					navigate;
				}
			})
			.catch((err) => {
				toast.error(err.response?.data?.message, { toastId: "logoutUserfail" });
				navigate;
			});
	}
};
export const adminLogout = (navigate) => {
	if (localStorage.getItem("encore-auth-token")) {
		logoutAdminUser().then((res) => {
			if (res.data.status === "failure") {
				toast.error(res.data.message, { toastId: "adminLogoutError" });
			}
			if (res.data.status === "success") {
				localStorage.removeItem("encore-auth-token");
				localStorage.removeItem("encore-device-token");
				localStorage.removeItem("encore-user-ssn");
				localStorage.removeItem("encore-admin")

				toast.success(res.data.message, { toastId: "adminUserLogoutSuccess" });
				navigate;
			}
		})
		.catch((err) => {
			toast.error(err.response?.data?.message, { toastId: "adminUserLogoutCatchError" });
			navigate;
		});
	}
}
