import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputGroup } from "../components/Form";
import "../assets/SCSS/pages/auth.scss";
import pageBg from "../assets/images/auth-bg.webp";
import mobileBg from "../assets/images/auth-bg-mobile.webp";
import logo from "../assets/images/encore.svg";
import { toast } from "react-toastify";
import axios from "axios";
import CONFIG from "../config/api.config";
import { useDispatch } from "react-redux";
import { signUpVerified } from "../features/login/AuthSlice";

// Sign Up Page
const SignUp = () => {
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	const [ssnError, setSsntError] = useState(false);
	const [contactError, setContactError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [loading, setLoading] = useState(false);

	const formSubmit = (e) => {
		e.preventDefault();
		const reqData = {

			userSSN: document.getElementsByName("user-ssn")[0].value,
			userContact: localStorage.getItem("encore-country-code") + document.getElementsByName("user-contact")[0].value,
			userPassword: document.getElementsByName("user-password")[0].value,
		};

		if (document.getElementsByName("user-contact")[0].value == "" && reqData.userPassword == "" && reqData.userSSN == "") {
			toast.error("Contact Number and Password Required", { toastId: "formfieldsReuered" });
			setContactError(true);
			setPasswordError(true);
			setSsntError(true);
			return;
		} else if (reqData.userSSN == "") {
			toast.error("SSN is Required", { toastId: "ssnrequeredError" });
			setSsntError(true);
			document.getElementsByName("user-ssn")[0].focus();
			return;
		} else if (document.getElementsByName("user-contact")[0].value == "") {
			toast.error("Contact Number is Required", { toastId: "contactRequeredError" });
			setContactError(true);
			document.getElementsByName("user-contact")[0].focus();
			return;
		} else if (reqData.userPassword == "") {
			toast.error("Password is Required", { toastId: "passwordRequeredError" });
			setPasswordError(true);
			document.getElementsByName("user-password")[0].focus();
			return;
		} else {
			setLoading(true);
			axios
				.post(CONFIG.signUpURL, reqData)
				.then((res) => {
					if (res.data.status === "failure") {
						document.getElementsByName("user-contact")[0].focus();
						setContactError(true);
						setPasswordError(true);
						setSsntError(true);
						toast.error(<span dangerouslySetInnerHTML={{ __html: res.data.message }}></span>, { toastId: "signUpError" });
						setLoading(false);
					}
					if (res.data.status === "success") {
						setContactError(false);
						setPasswordError(false);
						setSsntError(false);
						dispatch(signUpVerified(res.data.data));
						axios
							.post(
								CONFIG.getOtp,
								{
									userContact: res.data.data.userContact || localStorage.getItem("encore-country-code") + document.getElementsByName("user-contact")[0].value,
								},
								{
									headers: {
										Authorization: "Bearer " + res.data.data.access_token,
										// access_token: res.data.data.access_token,
										"Content-Type": "application/json",
									},
								}
							)
							.then((res) => {
								if (res.data.status === "failure") {
									toast.error(<span dangerouslySetInnerHTML={{ __html: res.data.message }}></span>, { toastId: "signupresponseFailure" });
									setLoading(false);
								}
								if (res.data.status === "success") {
									toast.success(<span dangerouslySetInnerHTML={{ __html: res.data.message }}></span>, { toastId: "signupresponseSuccess" });

									Navigate("/verify-otp");
									setLoading(false);
								}
							});
					}
				})
				.catch((err) => {
					setContactError(true);
					setPasswordError(true);
					setSsntError(true);
					document.getElementsByName("user-contact")[0].focus();
					toast.error(err.response?.data?.message, { toastId: "signUpcatchError" });
					setLoading(false);
				});
		}
	};

	document.title = "Encore | Sign-Up";

	return (
		<div className="auth-page-wrapper" >
			<picture>
				<source media="(min-width:768px)" srcSet={pageBg} />
				<img src={mobileBg} alt="auth-page" height="970px" width="1920px" className="bg-img" />
			</picture>
			<div className="logo">
				<img src={logo} alt="Encore" height="40px" width="171px" />
			</div>
			<div className="auth-card ml-auto">
				<h4 className="card-title text-center">SignUp</h4>
				<form onSubmit={formSubmit}>
					<InputGroup label="SSN" icon="id-card" placeholder="SSN Number" type="number" name="user-ssn" error={ssnError} validate="ssn" setError={setSsntError} />
					<InputGroup label="Contact Number" icon="call" placeholder="Contact Number" type="number" name="user-contact" error={contactError} validate="mobile" setError={setContactError} />
					<InputGroup label="Password" icon="lock" placeholder="************" type="password" name="user-password" error={passwordError} setError={setPasswordError} />
					<button type="submit" className={`btn btn-primary full mt-3 ${loading ? "loading" : ""}`}>
						Submit
					</button>
				</form>
				<div className="text-center mt-3">
					Already Have an Account? <Link to={"/login"}>Login</Link>
				</div>
			</div>
		</div>
	);
};

export default SignUp;