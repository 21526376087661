import PrivacyPolicyWithoutLogin from "../pages/PrivacyPolicyWithoutLogin";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API = "/api";
const SOCKET = "https://chat.encorehealthcare.us";

// const SOCKET = "https://encoresocket.demo.brainvire.dev";

export default {
	siteUrl: BASE_URL,
	axiosBaseUrl: BASE_URL + API,
	loginURL: "/user/signIn",
	getUserInfo: "/user/getUserInfo",
	deleteUser: "/user/deleteUser",
	signUpURL: "/user/signUp",
	getSpaciality: "/disease/getDiseaseListByType",
	getTelemedicine: "/disease/getTelemedicine",
	getOtp: "/user/getOtp",
	verifyOtp: "/user/verifyOtp",
	getUserPlanDetails: "/user/plan/getUserPlanDetails",
	updateNewPassword: "/user/updateNewPassword",
	changePassword: "/user/changePassword",
	forgotPassword: "/user/forgotPassword",
	getCommonSpecialist: "/specialist/getCommonSpecialist",
	getCommonCondition: "/disease/getCommonCondition",
	updateUserProfilePic: "/user/updateUserProfilePic",
	getPediatrixDetails: "/pediatrics/getPediatrixDetails",
	privacyPolicy: "/user/privacyPolicy",
	PrivacyPolicyWithoutLogin: "/user/privacyPolicyWithoutLogin",
	sendNotification: "/user/sendNotification",
	updateDeviceData: "/user/updateDeviceData",
	verifyNotification: "/user/verifyNotification",
	messageFromChairman: "/user/messageFromChairman",
	aboutUs: "/user/aboutUs",
	listOfAdditionalFacility: "/user/listOfAdditionalFacility",
	additionalFacilityDetails: "/user/additionalFacilityDetails",
	refreshToken: "/user/refreshToken",
	updateMobileNumber: "/user/updateMobileNumber",
	verifyOtptoUpdateNumber: "/user/verifyOtptoUpdateNumber",
	markTermCondition: "/user/markTermCondition",
	allEmployees: "/user/getUserListing",
	employeeDetail: "/user/getUserDetails",
	changeEmployeeStatus: "/user/updateUserStatus",
	userStatus: "/user/getUserStatus",

	exportUser:"/user/exportUsers",
	getUserSummary: "/user/getUsersSummary",
	downloadSampleFile: "/users/downloadUserSampleSheetCsv",
	importFile:"/users/importUserSheet",
	uploadCardFile:"/user/plan/uploadMedicalDocument",

	spouceChildDeleleDetails: "/user/deleteUserRelative",
	deleleCardDetails: "user/plan/deleteMedicalDocument",
               
	logout: "/user/logout",
	redirectionURL: "https://connect.werally.com/medicalProvider/root", // Static redirect URL
	
	socketURL: SOCKET, // Socket URL for chat server
	// socketURL: "http://localhost:8000/", // Socket URL for chat server
	getChatUserListing: SOCKET + "/users/list",
	adminLoginURL: SOCKET + "/user/login",
	adminLogoutURL: SOCKET + "/user/logout",
	adminChangePassword: SOCKET + "/user/changePassword",
	adminForgotPassword: SOCKET + "/user/forgotPassword",
	adminVerifyOtp: SOCKET + "/user/verifyOtp",
	adminupdatePassword: SOCKET + "/user/updatePassword",
	getProviders: SOCKET + "/user/getProviders",
	userChatList: SOCKET + "/chat/list",
	terminateChatSession: SOCKET + "/chat/terminateSession",

	userPlanDetails: "https://docs.google.com/spreadsheets/d/11LHVWSLBYqf8zNzJMa4KGxI63i9QH1J17NNehBhKcWY/edit#gid=0",
};
