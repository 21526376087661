import React, { useEffect, useState } from "react";
import ProfileSidebar from "./../components/ProfileSidebar";
import "../assets/SCSS/pages/profile.scss";
import { Outlet } from "react-router-dom";

// Profile Layout Page
const ProfileLayout = () => {
	return (
		// <main className="page">
		// 	<div className="page-header">
		// 		<div className="page-header-left">
		// 			<h3 className="mb-0 d-flex align-center">My Profile</h3>
		// 		</div>
		// 	</div>
		// 	<div className="row align-start">
		// 		{/* <div className="sidebar">
		// 			<ProfileSidebar />
		// 		</div> */}
		// 		<div className="content full">
		// 			<Outlet />
		// 		</div>
		// 	</div>
		// </main>

		<Outlet />
	);
};

export default ProfileLayout;