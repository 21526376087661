import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InputGroup } from "../components/Form";
import "../assets/SCSS/pages/auth.scss";
import pageBg from "../assets/images/auth-bg.webp";
import mobileBg from "../assets/images/auth-bg-mobile.webp";
import logo from "../assets/images/encore.svg";
import { useNavigate } from "react-router-dom";
import CONFIG from "../config/api.config";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { login } from "../features/login/AuthSlice";

// Login Page Component
const Login = () => {
	const dispatch = useDispatch();
	const [contactError, setContactError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [loading, setLoading] = useState(false);
	localStorage.removeItem("encore-auth-token");
	localStorage.removeItem("encore-device-token");
	localStorage.removeItem("encore-user-ssn");
	localStorage.removeItem("encore-admin")
	const SetCountryCode = (code) => {
		localStorage.setItem("encore-country-code", `+${code}`);
	};

	useEffect(() => {
		SetCountryCode(1);
	}, []);
	let navigate = useNavigate();

	const loginCheck = (e) => {
		e.preventDefault();

		const reqData = {
			userContact: localStorage.getItem("encore-country-code") + document.getElementsByName("user-contact")[0].value,
			userPassword: document.getElementsByName("user-password")[0].value,
		};
		if (document.getElementsByName("user-contact")[0].value == "" && reqData.userPassword == "") {
			toast.error("Contact Number and Password Required.", { toastId: "reueredFieldsMsg" });
			setContactError(true);
			setPasswordError(true);
			document.getElementsByName("user-contact")[0].focus;
			return;
		} else if (document.getElementsByName("user-contact")[0].value == "") {
			toast.error("Contact Number is Required.", { toastId: "contactNumberError" });
			setContactError(true);
			document.getElementsByName("user-contact")[0].focus;
			return;
		} else if (reqData.userPassword == "") {
			toast.error("Password is Required.", { toastId: "passqordRequeredError" });
			setPasswordError(true);
			document.getElementsByName("user-contact")[0].focus;
			return;
		} else {
			setLoading(true);
			axios
				.post(CONFIG.loginURL, reqData)
				.then((res) => {
					if (res.data.status === "failure") {
						document.getElementsByName("user-contact")[0].focus;
						setContactError(true);
						setPasswordError(true);
						toast.error(res.data.message, { toastId: "loginFailureError" });
						setLoading(false);
					}
					if (res.data.status === "success") {
						if (res.data.data.isAdmin == true) {
							setContactError(false);
							setPasswordError(false);
							dispatch(login(res.data));
							localStorage.setItem('isAdmin', res.data.data.isAdmin ? 'true' : 'false')
							toast.success(res.data.message, { toastId: "loginSuccessMsg" });
							navigate("/employees", { replace: true });
							setLoading(false);
						} else {
							toast.error("Access denied. You do not have permission to access admin website. If you are a mobile app user, please use the mobile app to login.", { toastId: "accessDeniedError" });
						}
						setLoading(false)
					}
				})
				.catch((err) => {
					console.log('Errr----', err);
					setContactError(true);
					setPasswordError(true);
					document.getElementsByName("user-password")[0].focus;
					toast.error(err.response?.data?.message, { toastId: "loginCatchError" });
					setLoading(false);
				});
		}
	};
	document.title = "Encore | Login";
	return (
		<div className="auth-page-wrapper">
			<picture>
				<source media="(min-width:768px)" srcSet={pageBg} />
				<img src={mobileBg} alt="auth-page" height="970px" width="1920px" className="bg-img" />
			</picture>
			<div className="logo">
				<img src={logo} alt="Encore" height="40px" width="171px" />
			</div>
			<div className="auth-card ml-auto">
				<h4 className="card-title text-center">Login</h4>
				<form onSubmit={loginCheck}>
					<InputGroup label="Contact Number" icon="call" placeholder="Contact Number" type="number" name="user-contact" error={contactError} setError={setContactError} validate="mobile" autoFocus="true" />
					<InputGroup label="Password" icon="lock" placeholder="************" type="password" name="user-password" error={passwordError} setError={setPasswordError} />
					<div className="text-right fw-500">
						<Link to="/forgot-password">Forgot Password?</Link>
					</div>
					<button type="submit" className={`btn btn-primary full mt-3 ${loading ? "loading" : ""}`}>
						Login
					</button>
					<div className="text-center mt-3">
						Don't Have an Account? <Link to={"/signup"}>Sign Up</Link>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;