import React, { useState } from "react";
import { InputGroup } from "../../components/Form";
import "../../assets/SCSS/pages/auth.scss";
import pageBg from "../../assets/images/auth-bg.webp";
import mobileBg from "../../assets/images/auth-bg-mobile.webp";
import logo from "../../assets/images/encore.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../config/api.config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AdminCreateNewPassword = () => {
	let navigate = useNavigate();
	const userDetail = useSelector((state) => state.auth);
	const [passwordError, setPasswordError] = useState(false);
	const [loading, setLoading] = useState(false);

	const resetPass = (e) => {
		e.preventDefault();
		const reqData = {
			userPassword: document.getElementsByName("user-password")[0].value,
		};
		if (reqData.userPassword == "") {
			toast.error("Password Required");
			setPasswordError(true);
			document.getElementsByName("user-password")[0].focus;
			return;
		}
		setLoading(true);
		axios
			.post(
				CONFIG.adminupdatePassword,
				{
					password: reqData.userPassword,
				},
				{
					headers: {
						Authorization: "Bearer " + userDetail.user.access_token,
						// access_token: userDetail.user.access_token,
						"Content-Type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.status === "failure") {
					toast.error(res.data.message);
					setLoading(false);
				}
				if (res.data.status === "success") {
					toast.success(res.data.message);
					navigate("/admin");
					setLoading(false);
				}
			});
	};

	document.title = "Encore | Set New Password";

	return (
		<div className="auth-page-wrapper">
			<picture>
				<source media="(min-width:768px)" srcSet={pageBg} />
				<img src={mobileBg} alt="auth-page" height="970px" width="1920px" className="bg-img" />
			</picture>
			<div className="logo">
				<img src={logo} alt="Encore" height="40px" width="171px" />
			</div>
			<div className="auth-card ml-auto">
				<h4 className="card-title text-center">Create New Password</h4>
				<form onSubmit={resetPass}>
					<InputGroup label="Password" icon="lock" placeholder="Password" type="password" name="user-password" error={passwordError} setError={setPasswordError} />
					<button className={`btn btn-primary full ${loading ? "loading" : ""}`} type="submit">
						Confirm
					</button>
				</form>
				<div className="text-center mt-3">
					<a
						href=""
						onClick={(e) => {
							e.preventDefault();
							navigate(-1);
						}}>
						Back
					</a>
				</div>
			</div>
		</div>
	);
};

export default AdminCreateNewPassword;
