import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import ChatPopup from "./chat/ChatPopup";
import ChatPopup from "../chat/employee/ChatPopup";
import SocialLinks from "./Social-Links";

import "../assets/SCSS/components/_footer.scss";

// Footer Content
const Footer = () => {
	const location = useLocation();
	const { id } = useParams();
	const [showChat, setShowChat] = useState(false);
	const date = new Date();

	return (
		<>
			<ChatPopup />
			<footer className="site-footer">
				<div className="container">
					<div className="copy-text">
						Copyright {date.getFullYear()}{" "}
						<a className="decoration-none" href="https://encore.bz/" target="_blank">
							Encore Enterprises, Inc.
						</a>{" "}
						| All Rights Reserved |{" "}
						<Link to={"/policy"} className=" decoration-none">
							Privacy Policy
						</Link>{" "}
						|{" "}
						<a className="decoration-none" href="https://encorehr.zendesk.com/hc/en-us" target="_blank">
							FAQs
						</a>
					</div>
					<SocialLinks />
				</div>
			</footer>
		</>
	);
};
export default Footer;
