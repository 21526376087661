import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InputGroup } from "../../components/Form";
import "../../assets/SCSS/pages/auth.scss";
import pageBg from "../../assets/images/auth-bg.webp";
import mobileBg from "../../assets/images/auth-bg-mobile.webp";
import logo from "../../assets/images/encore.svg";
import { useNavigate } from "react-router-dom";
import CONFIG from "../../config/api.config";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Login Page Component
const Admin = () => {
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [loading, setLoading] = useState(false);

	localStorage.removeItem("encore-auth-token");
	localStorage.removeItem("encore-device-token");
	localStorage.removeItem("encore-user-ssn");
	localStorage.removeItem("encore-admin")

	let navigate = useNavigate();

	const loginCheck = (e) => {
		e.preventDefault();

		const reqData = {
			emailId: document.getElementsByName("user-email")[0].value,
			password: document.getElementsByName("user-password")[0].value,
		};

		if (document.getElementsByName("user-email")[0].value == "" && reqData.userPassword == "") {
			toast.error("Email and Password Required.");
			setEmailError(true);
			setPasswordError(true);
			document.getElementsByName("user-email")[0].focus;
			return;
		} else if (document.getElementsByName("user-email")[0].value == "") {
			toast.error("Email is Required.");
			setEmailError(true);
			document.getElementsByName("user-email")[0].focus;
			return;
		} else if (reqData.password == "") {
			toast.error("Password is Required.");
			setPasswordError(true);
			document.getElementsByName("user-email")[0].focus;
			return;
		} else {
			setLoading(true);
			axios
				.post(CONFIG.adminLoginURL, reqData)
				.then((res) => {
					if (res.data.status === "failure") {
						document.getElementsByName("user-email")[0].focus;
						setEmailError(true);
						setPasswordError(true);
						toast.error(res.data.message);
						setLoading(false);
					}
					if (res.data.status === "success") {
						setEmailError(false);
						setPasswordError(false);
						localStorage.setItem("encore-auth-token", res.data.data.accessToken)
						localStorage.setItem("encore-admin", true)
						toast.success(res.data.message);
						navigate("/admin/chat", { replace: true });
						setLoading(false);
					}
				})
				.catch((err) => {
					setEmailError(true);
					setPasswordError(true);
					document.getElementsByName("user-password")[0].focus;
					toast.error(err.response?.data?.message);
					setLoading(false);
				});
		}
	};

	document.title = "Encore | Admin Login";

	return (
		<div className="auth-page-wrapper">
			<picture>
				<source media="(min-width:768px)" srcSet={pageBg} />
				<img src={mobileBg} alt="auth-page" height="970px" width="1920px" className="bg-img" />
			</picture>
			<div className="logo">
				<img src={logo} alt="Encore" height="40px" width="171px" />
			</div>
			<div className="auth-card ml-auto">
				<h4 className="card-title text-center">Admin Login</h4>
				<form onSubmit={loginCheck}>
					<InputGroup label="User Email" icon="email" placeholder="User Email" type="email" name="user-email" error={emailError} setError={setEmailError} validate="email" autoFocus="true" />
					<InputGroup label="Password" icon="lock" placeholder="************" type="password" name="user-password" error={passwordError} setError={setPasswordError} />
					<div className="text-right fw-500">
						<Link to="/admin/forgot-password">Forgot Password?</Link>
					</div>
					<button type="submit" className={`btn btn-primary full mt-3 ${loading ? "loading" : ""}`}>
						Login
					</button>
				</form>
			</div>
		</div>
	);
};

export default Admin;
