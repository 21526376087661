import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CONFIG from "../../config/api.config";
import io from 'socket.io-client';
import "../../assets/SCSS/components/_chat-popup.scss";
import AdminPic from "../../assets/images/user-profile.png";
import { getUserChatList, markTermCondition } from "../../axios/apicall";
import { CHAT_TYPE } from "../admin/Chat";
import NotificationSound from "../../assets/audio/encore-notification.mp3";
import socket from "socket.io-client/lib/socket";
import moment from "moment/moment";

const ChatPopup = () => {
	const chatPopupRef = useRef(null);
	const user = useSelector((state) => state.auth.user);
	const [showPopup, setShowPopup] = useState(false);
	const [notifyUser, setNotifyUser] = useState();
	const [acceptTC, setAcceptTC] = useState(false);
	const [msgPage, setMsgPage] = useState(0);
	const [chatMessageInput, setChatMessageInput] = useState();
	const [totalpage, setTotalpage] = useState(0);
	const [totalChatsPerPage, setTotalChatsPerPage] = useState(0);
	const [messageList, setMessageList] = useState([]);
	const [updatedSocket, setSocket] = useState('');
	const [unreadMessageCount, setUnreadMessageCount] = useState(localStorage.getItem('encore-read-count'));
	const NotificationSoundref = useRef(null);

	const bottomRef = useRef(null);
	const [chatUserDetail, setChatUserDetail] = useState('');

	let readCount = parseInt(localStorage.getItem("encore-read-count")) || 0;
	useEffect(() => {
		setAcceptTC(user?.isChatTermAndCondition)
	}, [user]);

	const updatChatCounts = (a) => {
		readCount = parseInt(a);
		localStorage.setItem("encore-read-count", a)
		setUnreadMessageCount(a)
	}

	const notificationReceived = () => {
		const popupShown = document.querySelector(".chat-popup")

		if (popupShown === null) {
			toast("New Message Received", { toastId: "MsgReceived" });
			NotificationSoundref.current.play();
			updatChatCounts(parseInt(readCount) + 1)
		}
	}
	useEffect(() => {
		if (!user?.id || !acceptTC) {
			return;
		}

		const userDetail = {
			"groupName": user?.group?.groupName,
			"senderId": `${user?.id}`,
			"groupMembers": user?.group?.groupMembers,
			"type": CHAT_TYPE
		}
		setChatUserDetail(userDetail);
		userMessageListing();
		// configure socket
		const socket = io(CONFIG.socketURL, { query: `senderId=${userDetail.senderId}&groupName=${userDetail.groupName}` });

		// Connection event and Join current socket
		socket.on("connect", () => {
			localStorage.setItem("encore-read-count", 0)
			setSocket(socket);
			if (userDetail) {
				socket.emit("join", userDetail)
			}
		});

		// Disconnect event socket
		socket.on("disconnect", () => {
			console.log("Session Disconnected");
		});

		// Received Event and update chat listig
		socket.on("received", (data) => {
			if (data.senderId == 1) {
				notificationReceived()
			}

			getUserChatList(user?.id, user?.group?.groupName, msgPage).then((res) => {
				setMessageList(res.data?.data?.data)
				setTimeout(() => {
					bottomRef.current?.scrollIntoView({ behavior: "smooth" });
				}, 10);
			}).catch((err) => { console.log(err?.response) })
		});

		// Terminate current socket session event
		socket.on('terminateSession', (data) => {
			localStorage.setItem("encore-read-count", 0)
			updatChatCounts(0)
			socket.disconnect();
			toast(data.msg, { toastId: "teminateSession" })
			setShowPopup(false);
			setMessageList([])
			socket.connect();
		})

		socket.on("notifyUnreadGlobal", (data) => {
			localStorage.setItem("encore-read-count", data[0]?.count)
			setUnreadMessageCount(data[0]?.count)
		})

		socket.on("connect_error", (err) => {
			console.log(err);
		});
	}, [user, acceptTC]);

	// get message listing
	const userMessageListing = () => {
		if (user.id) {
			setMsgPage(0)
			getUserChatList(user?.id, user?.group?.groupName, msgPage).then((res) => {
				setTotalpage(res.data?.data?.totalpage)
				setTotalChatsPerPage(res.data?.data?.perpage)
				setMessageList(res.data?.data?.data)
				setTimeout(() => {
					bottomRef.current?.scrollIntoView({ behavior: "smooth" });
				}, 10);
			}).catch((err) => { console.log(err?.response) })
		}
	}

	// Chat load more data on scroll to top
	const loadMoreChats = (e) => {
		if (e.currentTarget.scrollTop === 0) {
			if (msgPage < totalpage) {
				getUserChatList(user?.id, user?.group?.groupName, msgPage + 1).then((res) => {
					res.data?.data?.data.map((newData) => {
						messageList && messageList.unshift(newData)
					})
				}).catch((err) => { console.log(err?.response) })
				setMsgPage(msgPage + 1)
				setTimeout(() => {
					const firstOldList = document.querySelectorAll('.message-date-group .chat-message-item')
					if (firstOldList.length > totalChatsPerPage) {
						firstOldList[totalChatsPerPage + 1].scrollIntoView()
					}
				}, 10);
			}
		}
	}

	// Confirm for Terms and conditions
	const checkConfirmationTC = () => {
		if (acceptTC) {
			setShowPopup(true);
			setMsgPage(0)
			updatChatCounts(0)
			setTimeout(() => {
				bottomRef.current?.scrollIntoView();
			}, 10);
		} else {
			updatChatCounts(0)
			setShowPopup(false)
			chatPopupRef.current = toast(
				<div className="warning-toast text-center">
					<h5 className="mb-1">Terms & Conditions</h5>
					<p className="mb-2">I Accept all the terms & Conditions</p>
					<div className="mb-3"><a href="/terms" target={"_blank"}>Terms And Conditions</a></div>
					<button className="btn outline mr-1 ml-1 mb-1" onClick={() => confirmTC("no")}>
						No
					</button>
					<button className="btn mr-1 ml-1 mb-1" onClick={() => confirmTC("yes")}>
						Yes
					</button>
				</div>,
				{
					position: "top-center",
					autoClose: false,
					hideProgressBar: true,
					closeButton: false,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
					toastId: "TermsOfUse",
					onOpen: () => {
						document.querySelector('.Toastify').classList.add("overlay")
					},
					onclose: () => {
						document.querySelector('.Toastify').classList.remove("overlay")
					}
				}
			);
		}
	};

	// Check Confirm for Terms and conditions
	const confirmTC = (cnf) => {
		if (cnf == "yes") {
			toast.dismiss(chatPopupRef.current);
			markTermCondition().then((res) => {
				if (res.data.status === "failure") {
					setAcceptTC(false);
				}
				if (res.data.status === "success") {
					setAcceptTC(true);
				}
			})

			setShowPopup(true);
			updatChatCounts(0)
			setTimeout(() => {
				bottomRef.current?.scrollIntoView({ behavior: "smooth" });
			}, 100);
			document.querySelector('.Toastify').classList.remove("overlay")
		} else {
			updatChatCounts(0)
			setShowPopup(false);
			toast.dismiss(chatPopupRef.current);
			document.querySelector('.Toastify').classList.remove("overlay")
		}
	};

	// Send Text message
	const chatMsgSend = (e) => {
		e.preventDefault();
		updatedSocket.emit("chat message", chatMessageInput, chatUserDetail.senderId, [], chatUserDetail.type, chatUserDetail.groupName)
		setChatMessageInput("");
		userMessageListing();
	}

	// Convert runtime date
	// const convetDate = (date) => {
	// 	const msgDate = new Date(date)
	// 	let msgHours = msgDate.getHours();
	// 	let msgMinutes = msgDate.getMinutes();
	// 	let msgAmPm = msgHours >= 12 ? 'PM' : 'AM';
	// 	msgHours = msgHours % 12;
	// 	msgHours = msgHours ? msgHours : 12; // the hour '0' should be '12'
	// 	msgHours = msgHours < 10 ? '0' + msgHours : msgHours;
	// 	msgMinutes = msgMinutes < 10 ? '0' + msgMinutes : msgMinutes;
	// 	let msgStrTime = msgHours + ':' + msgMinutes + ' ' + msgAmPm;

	// 	return msgStrTime;
	// }

	return (
		user?.id && <div className="chat-popup-container">
			<button className="btn-chat" onClick={() => checkConfirmationTC()}>
				{/* <i className="icon-chat-alt"></i> */}
				Chat
				<span className={`unread-count-badge ${!unreadMessageCount || unreadMessageCount == 0 || showPopup ? "hide" : ""}`}>{unreadMessageCount}</span>
			</button>
			{showPopup && (
				<div className="chat-popup">
					<div className="chat-popup-header">
						<div className="chat-user">
							<div className="chat-user-pic">
								{/* <img src={user?.userProfilePic || AdminPic} alt={user.userFullName} /> */}
								<img src={user?.group?.profilePic || AdminPic} alt={"Admin"} />
								{/* <span className={`user-status online`}></span> */}
							</div>
							<div className="chat-user-detail">
								{user?.group?.adminName}
								{/* <small>{user?.userRealation}</small> */}
							</div>
						</div>
						<button
							onClick={() => {
								setShowPopup(false);
							}}>
							<i className="icon-close"></i>
						</button>
					</div>
					<div className="chat-popup-body" id="chatPopupBody" onScroll={(e) => loadMoreChats(e)}>
						{
							messageList?.length ? (
								messageList?.map((msg) => {
									return <ul className="chat-body-messages" key={Math.random()}>
										<li className="msg-date" key={`date-${msg.date}-${Math.random()}`}><span>{msg.date}</span></li>
										{msg.message?.map((msgContent) => {
											return <li className={msgContent.senderId != 1 ? "self" : ""} key={msgContent.createdAt + Math.random()}>
												{msgContent.senderId == 1 && <div className="message-user-pic">
													<img src={AdminPic} alt="ADMIN" />
												</div>}
												<div className="message-content-wrapper">
													{msgContent.senderId == 1 && <>
														<small>{user?.group?.adminName}</small>
													</>
													}
													<div className="chat-message-content">
														{msgContent.message}
													</div>
													<small className="chat-message-time">
														{
															moment(msgContent.createdAt).utcOffset(-360).format('hh:mm A')
														}
													</small>
												</div>
											</li>
										})}
									</ul>
								})
							) : (
								<div className="no-data">
									No Messages.
								</div>
							)
						}
						<span ref={bottomRef}></span>
					</div>
					<div className="chat-popup-footer">
						<form onSubmit={(e) => chatMsgSend(e)} className="chat-popup-form">
							{/* <span style={{ position: "absolute", top: 0, left: 0 }}>{adminTypig}</span> */}
							<input type="text" placeholder="Type a message" value={chatMessageInput || ""} onChange={(e) => { setChatMessageInput(e.target.value) }} />
							<button type="submit">
								<i className="icon-send"></i>
							</button>
						</form>
					</div>
				</div>
			)}
			<audio ref={NotificationSoundref} src={NotificationSound} style={{ display: "none" }} />
		</div>
	);
};

export default ChatPopup;
