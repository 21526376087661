import React from "react";
import bannerImage2 from "../assets/images/bannerimg2.png";
import bannerImage3 from "../assets/images/home-banner-2.png";
import bannerImage4 from "../assets/images/home-banner-3.png";
import bannerImage5 from "../assets/images/home-banner-4.png";
import approvalImg from "../assets/images/approval.svg";
import "../assets/SCSS/pages/home.scss";
import ImageCard from "../components/ImageCard";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CONFIG from "../config/api.config";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { logOut } from "../features/logout/Logout";
import { getUserInfoAction, logout } from "../features/login/AuthSlice";
import { fetchToken } from "../config/firebase";
import { getUserInfo, getUserStatus } from "../axios/apicall";

const Home = () => {
  
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSpouseUnapproved, setIsSpouseUnapproved] = useState(false);
  const [approvalText, setApprovalText] = useState("Send Notification");
  const [isTokenFound, setTokenFound] = useState(
    localStorage.getItem("encore-device-token") || false
  );
  const [autoCloseTime, setAutoCloseTime] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const userType = user?.userTiear;
  const userRealation = user?.userRealation;
  const userStatus = user?.status;

  let cancelToken;
  const customId = "custom-id-yes";

  // Fetch Device Token
  fetchToken(setTokenFound);

  // Retry If Token not found
  if (!isTokenFound) {
    fetchToken(setTokenFound);
  }

  // Update Device Token
  const updateDeviceTokent = () => {
    if (isTokenFound) {
      setLoading(true);
      // if (cancelToken) {
      // 	cancelToken.cancel("Old Req Cancelled");
      // }
      cancelToken = axios.CancelToken.source();
      if (!isTokenFound) {
        fetchToken(setTokenFound);
      }
      axios
        .post(
          CONFIG.updateDeviceData,
          {
            deviceToken: localStorage.getItem("encore-device-token"),
            deviceType: "web",
          },
          {
            cancelToken: cancelToken.token,
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "failure") {
            toast.error(res.data.message);
            setLoading(false);
          }
          if (res.data.status === "success") {
            // toast.success(res.data.message);
            getUserInfo()
              .then((res) => {
                if (res.data.status === "failure") {
                  toast.error(res.data.message);
                  setLoading(false);
                  logOut(dispatch(logout()), navigate("/login"));
                }
                if (res.data.status === "success") {
                  dispatch(getUserInfoAction(res.data));
                  setLoading(false);
                }
              })
              .catch((err) => {
                toast.error(err.response?.data?.message);
                setLoading(false);
                logOut(dispatch(logout()), navigate("/login"));
              });
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getUserStatus().then((res) => {
      if (res.data?.data?.userStatus === 0) {
        toast.error(res.data.message);
        localStorage.removeItem("encore-auth-token");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    });
  }, []);

  useEffect(() => {
    updateDeviceTokent();
  }, [isTokenFound]);

  useEffect(() => {
    if (userRealation == "Spouse" && userStatus == "pending") {
      setIsSpouseUnapproved(true);
    }
    if (userRealation == "Spouse" && userStatus == "reject") {
      setIsSpouseUnapproved(true);
      toast.error(
        <div>
          <p>Sorry, the employee has rejected your request.</p>
          <button
            className="btn sm"
            onClick={() =>
              logOut(
                dispatch(logout()),
                navigate("/login"),
                setAutoCloseTime(5000)
              )
            }
          >
            Logout
          </button>
        </div>,
        {
          autoClose: autoCloseTime,
          closeButton: false,
          draggable: false,
          toastId: customId,
        }
      );
    }
    if (localStorage.getItem("encore-admin")) {
      navigate("/admin/chat", { replace: true });
    }
  }, [user]);

  const requestApproval = () => {
    setLoading(true);
    if (approvalText == "Retry") {
      getUserInfo()
        .then((res) => {
          if (res.data.data.status === "pending") {
            axios
              .post(
                CONFIG.sendNotification,
                {
                  // userSSN: user.parentUserSSN,
                },
                {
                  headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                if (res.data.status === "failure") {
                  toast.error(res.data.message);
                  setLoading(false);
                }
                if (res.data.status === "success") {
                  toast.success(res.data.message);
                  setLoading(false);
                }
              })
              .catch((err) => {
                toast.error(err.response?.data?.message);
                setLoading(false);
              });
          } else if (res.data.data.status == "reject") {
            toast.error("Your request was rejected.");
            logOut(dispatch(logout()), navigate("/login"));
          } else {
            window.location.reload(true);
          }
          if (res.data.status === "success") {
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
          setLoading(false);
          logOut(dispatch(logout()), navigate("/login"));
        });
    } else {
      axios
        .post(
          CONFIG.sendNotification,
          {
            // userSSN: user.parentUserSSN,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "failure") {
            toast.error(res.data.message);
            setApprovalText("Retry");
            setLoading(false);
          }
          if (res.data.status === "success") {
            toast.success(res.data.message);
            setApprovalText("Retry");
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
          setLoading(false);
        });
    }
  };
  document.title = "Encore | Dashboard";

  return (
    <>
      {isSpouseUnapproved && (
        <div className="user-unapproved text-center">
          <img
            src={approvalImg}
            alt="Approval"
            className="user-unapproved-img mb-1"
          />
          <h1 className="mb-1 text-primary">Hi, {user?.userFirstName}</h1>
          <h4 className="mb-3 fw-300">
            Your Approval is{" "}
            {userStatus == "pending" ? (
              <span className="text-success fw-500">Pending</span>
            ) : (
              <span className="text-danger fw-500">Rejected</span>
            )}
          </h4>
          {userStatus == "pending" && (
            <button
              onClick={() => requestApproval()}
              className={`btn btn-primary outline ${loading ? "loading" : ""}`}
            >
              {approvalText}
            </button>
          )}
        </div>
      )}

      {!isSpouseUnapproved && (
        <main className="page">
          <div className="row">
            {userType && userType == 2 ? (
              <>
                <div className="col-full mb-3">
                  <div
                    className="home-card"
                    style={{ backgroundImage: `url(${bannerImage5})` }}
                  >
                    <div className="home-card-content-wrapper">
                      <h2>
                        Choose The Best Expert and Facilities for your
                        Healthcare
                      </h2>
                      <h3 className="home-card-content fw-400">
                        You are in Safe Hands
                        <br />
                        <span className="fw-600 mt-1">Looking For?</span>
                      </h3>
                      <div className="d-flex flex-wrap specialist-list-cta">
                        <Link
                          to={"/all-specialist"}
                          className="btn btn-primary mr-1 mb-1"
                        >
                          All Other specialists
                        </Link>
                        <Link
                          to={"/all-conditions"}
                          className="btn btn-primary mr-1 mb-1"
                        >
                          All Other Conditions
                        </Link>
                      </div>
                    </div>
                    <div className="specialist-list">
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Family Medicine
                      </a>
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Pediatrician
                      </a>
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Gynecology
                      </a>
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Endocrinologist
                      </a>
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Gastroenterology
                      </a>
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Heart Specialist
                      </a>
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Neurology ( Brain ){" "}
                      </a>
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Orthopedic
                      </a>
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Pulmonologist
                      </a>
                      <a href={CONFIG.redirectionURL} target="_blank">
                        Oncology
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-full mb-3">
                  <ImageCard
                    title="Direct Primary Care and Telemedicine"
                    bg={bannerImage2}
                    text="Connect with Expert now"
                    ctaText="Click Here"
                    ctaLink="/find-telemedicine"
                    price={0}
                  />
                </div>
                {/* <div className="col-full">
									<ImageCard title="Choose The Best Expert and Facilities for your Healthcare" bg={bannerImage1} text="Get the Best Pediatric Services" ctaText="Learn More" ctaLink="/about-us" mainBanner="true" />
								</div> */}
              </>
            ) : (
              <>
                {/* <div className="col-full mb-3">
									<ImageCard title="Choose The Best Expert and Facilities for your Healthcare" bg={bannerImage1} text="You are in Safe Hands" ctaText="Learn More" ctaLink="/about-us" mainBanner="true" />
								</div> */}
                <div className="col-full mb-3">
                  <ImageCard
                    title="Direct Primary Care and Telemedicine"
                    bg={bannerImage2}
                    text="Connect with Expert now"
                    ctaText="Click Here"
                    ctaLink="/find-telemedicine"
                    price={0}
                  />
                </div>
                <div className="col-half mb-sm-3">
                  <ImageCard
                    title={<>Plan A EHN Network</>}
                    bg={bannerImage3}
                    text="Get the Best Providers Services"
                    ctaText="Click Here"
                    ctaLink="/all-providers"
                  />
                </div>
                <div className="col-half">
                  <ImageCard
                    title={<>Plan A UMR Nexus Network</>}
                    bg={bannerImage4}
                    text="Connect with Expert now"
                    ctaText="Click Here"
                    external={true}
                    ctaLink="https://connect.werally.com/choose-plan-year?guideId=uhc&coverageType=medical"
                  />
                </div>
              </>
            )}
          </div>
        </main>
      )}
    </>
  );
};

export default Home;
