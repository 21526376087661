import React from "react";
import { Link } from "react-router-dom";

// Common Image Card Component
const ImageCard = (props) => {
	const { bg, title, text, ctaText, ctaLink, mainBanner, price, external } = props;
	return (
		<div className={`image-box ${mainBanner == "true" ? "main-banner" : ""}`}>
			<div className="box-bg" style={{ backgroundImage: `url(${bg})` }}></div>
			<div className="box-content">
				{mainBanner == "true" ? <h1 className="box-title" dangerouslySetInnerHTML={{ __html: title }}></h1> : <div className="box-title">{title} </div>}
				<p className="box-text">{text}</p>
				{external ? (
					<a href={ctaLink} target="_blank" className="btn btn-primary">
						{ctaText}
					</a>
				) : (
					<Link to={ctaLink} className="btn btn-primary">
						{ctaText} 
					</Link>
				)}
				{price >= 0 ? (
					<span className="box-price">
						<small>$</small>
						{price}
					</span>
				) : null}
			</div>
		</div>
	);
};

export default ImageCard;

