import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CONFIG from "../config/api.config";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { getCommonSpecialistList } from "../axios/apicall";
import NoData from "../components/NoData";

// All Specialist Listing page
const AllSpecialist = () => {
	const [dataOffset, setDataOffset] = useState(0);
	const [showLoader, setShowLoader] = useState(true);
	const [page, setPage] = useState(1);
	const [spaciality, setSpaciality] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [paginationCount, setPaginationCount] = useState(0);

	const defaultLimit = 24;
	const listInnerRef = useRef();

	let navigate = useNavigate();
	const reqData = {
		offset: dataOffset,
		limit: defaultLimit * page,
		search: "",
	};

	const loadSpacialityList = (token) => {
		setShowLoader(true);
		getCommonSpecialistList(reqData, token)
			.then((res) => {
				setTotalRecords(res.data.data.count);
				setSpaciality(res.data.data.specialist);
				setShowLoader(false);
				reqData.limit < totalRecords ? setPaginationCount(Array.from(Array(Math.ceil(totalRecords / reqData.limit)).keys())) : setPaginationCount(0);
			})
			.catch((err) => {
				toast.error(err.response?.data?.message);
			});
	};

	useEffect(() => {
		if (localStorage.getItem("encore-auth-token")) {
			loadSpacialityList(localStorage.getItem("encore-auth-token"));
		}
	}, [page]);

	const listscrollRef = useBottomScrollListener(() => {
		if (spaciality?.length < totalRecords) {
			setPage(page + 1);
		}
	});

	document.title = "Encore | Specialists";
	
	return (
		<main className="page infinite" ref={listscrollRef}>
			<Loader show={showLoader} />
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 text-primary d-flex align-center">
						<i className="cursor-pointer text-body mr-1 icon-back" onClick={() => navigate(-1)}></i>
						Specialists
					</h3>
				</div>
			</div>
			{spaciality?.length ? (
				<div className="care-list">
					{spaciality?.map((care) => {
						return (
							<a key={care.id} href={CONFIG.redirectionURL} target="_blank">
								{care.name}
							</a>
						);
					})}
				</div>
			) : (
				<NoData />
				// <h4 className="text-center">No Data</h4>
			)}
		</main>
	);
};

export default AllSpecialist;
