import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { getTelemedicineList } from "../axios/apicall";
import NoData from "../components/NoData";

const AllTelemedicine = () => {
	const [dataOffset, setDataOffset] = useState();
	const [showLoader, setShowLoader] = useState(true);
	const [page, setPage] = useState(1);
	const [spaciality, setSpaciality] = useState("");
	const [totalRecords, setTotalRecords] = useState(0);
	const [paginationCount, setPaginationCount] = useState(0);
	const [spacialitycontact, setSpacialityContact] = useState(0);
	const defaultLimit = 24;
	let navigate = useNavigate();

	const reqData = {
		type: "telemedicine",
		offset: dataOffset,
		limit: defaultLimit * page,
		search: "",
	};

	const loadSpacialityList = (token) => {
		setShowLoader(true);
		getTelemedicineList(reqData, token)
			.then((res) => {
				setTotalRecords(res.data.data.count);
				setSpaciality(res.data.data.specialities);
				setSpacialityContact(res.data.data.specialities[0].contact);
				setShowLoader(false);
				reqData.limit < totalRecords ? setPaginationCount(Array.from(Array(Math.ceil(totalRecords / reqData.limit)).keys())) : setPaginationCount(0);
			})
			.catch((err) => {
				toast.error(err.response?.data?.message);
			});
	};

	useEffect(() => {
		if (localStorage.getItem("encore-auth-token")) {
			loadSpacialityList(localStorage.getItem("encore-auth-token"));
		}
	}, [page]);

	const listscrollRef = useBottomScrollListener(() => {
		if (spaciality?.length < totalRecords) {
			setPage(page + 1);
		}
	});

	document.title = "Encore | Telemedicines";

	return (
		<main className="page infinite" ref={listscrollRef}>
			<Loader show={showLoader} />
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 text-primary d-flex align-center">
						<i className="cursor-pointer text-body mr-1 icon-back" onClick={() => navigate(-1)}></i>
						Telemedicine
					</h3>
				</div>
				<div className="page-header-right">
					{/* <i className="icon-info mr-3"></i> */}
					<a href={`tel:${spacialitycontact}`} className="btn outline">
						<i className="icon-call mr-1"></i> {spacialitycontact}
					</a>
				</div>
			</div>
			{spaciality?.length != 0 ? (
				<>
					<div className="care-list">

						{spaciality.map((care) => {
							return care.name == "Others" ? (
								<a className="order-last" key={care.id}>
									{care.name}
								</a>
							) : (
								<a key={care.id}>{care.name}</a>
							);
						})}
					</div>
				</>
			) : (
				<NoData />
				// <h4 className="text-center">No Data</h4>
			)}
		</main>
	);
};

export default AllTelemedicine;