import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../../assets/SCSS/components/_chat.scss";
import ChatSidebar from "../admin/ChatSidebar";

// Chat Global details
export const CHAT_TYPE = 'onetoone'
export const SENDER_ID = '1'

const Chat = () => {
	const navigate = useNavigate();

	if (!localStorage.getItem('encore-admin')) {
		navigate(-1, { replace: true })
	}

	return (
		<main className="page">
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 d-flex align-center text-primary">Messages</h3>
				</div>
			</div>
			{localStorage.getItem('encore-admin') && <div className="chat">
				<ChatSidebar />
				<div className="chat-listing">
					<Outlet />
				</div>
			</div>}
		</main>
	);
};

export default Chat;
