import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import doctorAvtarFemale from "../../assets/images/doctor-female.svg";
import doctorAvtarMan from "../../assets/images/doctor-male.svg";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import NoData from "../../components/NoData";
import { getProvidersList } from "../../axios/apicall";
import { useNavigate } from "react-router-dom";

// All Pediatrician Listing page
const AdminAllProviders = () => {
	let navigate = useNavigate();
	const [showLoader, setShowLoader] = useState(true);
	const [page, setPage] = useState(1);
	const [searchString, setSearchString] = useState("");
	const [totalRecords, setTotalRecords] = useState(0);
	const [doctorList, setDoctorList] = useState();
	const [wasLastList, setWasLastList] = useState(false);
	const defaultLimit = 9;

	if (!localStorage.getItem('encore-admin')) {
		navigate(-1, { replace: true })
	}

	const reqData = {
		limit: searchString?.length < 3 ? defaultLimit * page : "",
		offset: 0,
		search: searchString?.length > 2 ? searchString : "",
	};

	const loadPediatrician = (token) => {
		setShowLoader(true);
		getProvidersList(reqData, token)
			.then((res) => {
				if (!res.data.data.totalRecords) {
					setWasLastList(true);
				}

				setShowLoader(false);
				setDoctorList(res.data.data.data);
				setTotalRecords(res.data.data.totalRecords);
			})
			.catch((err) => {
				toast.error(err.response?.data?.message);
			});
	};

	useEffect(() => {
		if (localStorage.getItem("encore-auth-token")) {
			loadPediatrician(localStorage.getItem("encore-auth-token"));
		} else {
			toast.info("No Data Available.");
			navigate("/");
		}
	}, [wasLastList, page, searchString]);

	const listscrollRef = useBottomScrollListener(() => {
		if (doctorList?.length < totalRecords) {
			setPage(page + 1);
		}
	});

	const handleSearch = (e) => {
		e.preventDefault();
		setSearchString(e.target.value);
		loadPediatrician(localStorage.getItem("encore-auth-token"));
	};

	document.title = "Encore | Pediatricians";

	return (
		<main className="page infinite" ref={listscrollRef}>
			<Loader show={showLoader} />
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 text-primary d-flex align-center">
						<i className="cursor-pointer text-body mr-1 icon-back" onClick={() => navigate(-1)}></i>
						All Providers
					</h3>
				</div>
				<div className="page-header-right">
					<div className="form-group">
						<div className="input-group">
							<input name="search-location" className="form-control" type="text" placeholder="Search By Name or Speciality" value={searchString} onChange={(e) => handleSearch(e)} />
							<span className="input-icon">
								<i className="icon-target"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="card-list">
				{doctorList?.length != 0 &&
					doctorList?.map((doctor) => {
						return (
							<div className="doctor-card" key={"doctor-" + Math.random()}>
								<span className="doctor-image">{doctor.gender === "F" ? <img src={doctorAvtarFemale} alt={doctor.fullName} /> : <img src={doctorAvtarMan} alt={doctor.fullName} />}</span>
								<div className="doctor-card-detail">
									{/* {doctor.inNetwork && <span className="network-tag">In-Network</span>} */}
									<span className="network-tag">In-Network</span>
									<div className="doctor-card-header">
										<h5>
											{doctor.fullName}
											<span>{doctor.organizationName}</span>
										</h5>
									</div>
									<div className="doctor-speciality">{doctor.primarySpecialty}</div>
									<address className=" mb-2">
										{doctor.address}
										<br />
										{doctor.city} {doctor.state} {doctor.zipcode}
									</address>
									<a href={`tel: ${doctor.phoneNumber}`} className="decoration-none fw-700">
										<i className="icon-call mr-1"></i> {doctor.phoneNumber}
									</a>
								</div>
								<div className="doctor-location">
									{/* <a href={`https://www.google.com/maps/search/?api=1&query=${doctor.lat},${doctor.long}`} target="_blank">
										<i className="icon-location"></i>
									</a> */}
									<a href={`https://www.google.com/maps/search/?api=1&query=${doctor.organizationName.replaceAll(" ", "+")}`} target="_blank">
										<i className="icon-location"></i>
									</a>
								</div>
							</div>
						);
					})}
			</div>
			{totalRecords == 0 && <NoData />}
		</main>
	);
};

export default AdminAllProviders;
