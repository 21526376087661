import React from "react";
import chat from "../../assets/images/default-chat.svg";
const ChatDefault = () => {
	return (
		<div className="default-chat">
			<img src={chat} alt="Chat" />
		</div>
	);
};

export default ChatDefault;
