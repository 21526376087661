import React, { useState } from "react";
import { Link } from "react-router-dom";
import { InputGroup } from "../components/Form";
import "../assets/SCSS/pages/auth.scss";
import pageBg from "../assets/images/auth-bg.webp";
import mobileBg from "../assets/images/auth-bg-mobile.webp";
import logo from "../assets/images/encore.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CONFIG from "../config/api.config";
import { useDispatch } from "react-redux";
import { forgotVerify, forgotVerifyToken, signUpVerified } from "../features/login/AuthSlice";

const ForgotPassword = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [contactError, setContactError] = useState(false);
	const [loading, setLoading] = useState(false);

	const checkContact = (e) => {
		e.preventDefault();
		const reqData = {
			userContact: localStorage.getItem("encore-country-code") + document.getElementsByName("user-contact")[0].value,
		};
		if (document.getElementsByName("user-contact")[0].value == "") {
			toast.error("Contact Number Required");
			setContactError(true);
			return;
		} else {
			setLoading(true);
			axios
				.post(CONFIG.forgotPassword, reqData)
				.then((res) => {
					if (res.data.status === "failure") {
						document.getElementsByName("user-contact")[0].focus;
						setContactError(true);
						toast.error(res.data.message);
						setLoading(false);
					}
					if (res.data.status === "success") {
						setContactError(false);
						toast.success(res.data.message);
						dispatch(forgotVerify(res.data.data));
						dispatch(forgotVerifyToken(res.data.data.access_token));
						navigate("/verify-otp?component=forgotPassword");
						setLoading(false);
					}
				})
				.catch((err) => {
					setContactError(true);
					document.getElementsByName("user-contact")[0].focus;
					toast.error(err.response?.data?.message);
					setLoading(false);
				});
		}
	};

	document.title = "Encore | Forgot Password";

	return (
		<div className="auth-page-wrapper">
			<picture>
				<source media="(min-width:768px)" srcSet={pageBg} />
				<img src={mobileBg} alt="auth-page" height="970px" width="1920px" className="bg-img" />
			</picture>
			<div className="logo">
				<img src={logo} alt="Encore" height="40px" width="171px" />
			</div>
			<div className="auth-card ml-auto">
				<h4 className="card-title text-center">Forgot Password</h4>
				<form onSubmit={checkContact}>
					<InputGroup label="Contact Number" icon="call" placeholder="Contact Number" type="number" error={contactError} name="user-contact" validate="mobile" setError={setContactError} />
					<button type="submit" className={`btn btn-primary full ${loading ? "loading" : ""}`}>
						Submit
					</button>
				</form>
				<div className="text-center mt-3">
					<Link to={"/"}>Back</Link>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
