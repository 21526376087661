import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { messageFromChairman } from "../axios/apicall";
import Loader from "../components/Loader";

// Message From Chairman Page
const MessageFromChairman = () => {
	// let navigate = useNavigate();
	const [chairman, setChairman] = useState({});
	const [showLoader, setShowLoader] = useState(false);
	
	const chairmaanMessage = () => {
		setShowLoader(true);
		messageFromChairman()
			.then((message) => {
				if (message.data.status === "success") {
					setChairman(message.data.data);
					setShowLoader(false);
				} else {
					toast.error(message.data.message, { toastId: "msgFromChaiman" });
					setShowLoader(false);
				}
			})
			.catch((err) => console.log(err));
	};
	
	useEffect(() => {
		chairmaanMessage();
	}, []);

	document.title = "Encore | Message From Chairman";
	
	return (
		<main className="page">
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 text-primary d-flex align-center">
						{/* <i className="cursor-pointer text-body mr-1 icon-back" onClick={() => navigate(-1)}></i> */}
						Message from Chairman
					</h3>
				</div>
			</div>
			<div className="row align-start">
				<div className="content full">
					<Loader show={showLoader} />
					<div className="content-box">
						{chairman?.name && (
							<>
								<div className="user-card mb-3">
									<div className="user-image">
										<img src={chairman.profilePic} alt={chairman.name} />
									</div>
									<div className="user-detail">
										<h5 className="text-primary mb-1">{chairman.name}</h5>
										<span>{chairman.profession}</span>
									</div>
								</div>
								<div dangerouslySetInnerHTML={{ __html: chairman.description }}></div>
							</>
						)}
					</div>
				</div>
			</div>
		</main>
	);
};

export default MessageFromChairman;
