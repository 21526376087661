import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputGroup } from "../components/Form";
import "../assets/SCSS/pages/profile.scss";
import { useDispatch, useSelector } from "react-redux";
import CONFIG from "../config/api.config";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
// import profilePic from "../assets/images/user-profile.png";
import profilePic from "../assets/images/user-profile.png";
import OtpInput from "react18-input-otp";
import {
  getUserInfoAction,
  logout,
  updateProfilePic,
} from "../features/login/AuthSlice";
import { logOut } from "../features/logout/Logout";
import { deleteUser } from "../axios/apicall";

// User Profile page
const Profile = () => {
  const userDetail = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, seterror] = useState(false);
  const [otp, setotp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const {
    userProfilePic,
    userFirstName,
    userLastName,
    userSSN,
    userContact,
    userFullName,
  } = userDetail;
  const [verifyOtpToken, setVerifyOtpToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [ProfilePicture, setProfilePicture] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [contactError, setContactError] = useState(false);
  const [deleteCheck, setDeleteCheck] = useState(false);
  const deleteUserId = useRef(null);

  const ContactNumber = userContact?.slice(2);

  const [state, setState] = useState({
    fullName: userFullName,
    userSSN,
    userContact: Number(
      userContact?.substring(localStorage.getItem("encore-country-code").length)
    ),
  });

  useEffect(() => {
    setShowLoader(false);
  }, []);

  useEffect(() => {
    setState({
      fullName: userFullName,
      userSSN,
      userContact: Number(
        userContact?.substring(
          localStorage.getItem("encore-country-code").length
        )
      ),
    });

    !userProfilePic
      ? setProfilePicture(profilePic)
      : setProfilePicture(userProfilePic);
  }, [
    userProfilePic,
    userFirstName,
    userLastName,
    userSSN,
    userContact,
    userDetail,
    ProfilePicture,
  ]);

  // Profile Pic update and convert to base64
  const uploadProfilePic = (e) => {
    let fileName = e.target.files[0];
    let reader = new FileReader();
    let base64String;
    setLoading(true);

    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        let binaryData = e.target.result;

        //Converting Binary Data to base 64
        base64String = window.btoa(binaryData);
        axios
          .post(
            CONFIG.updateUserProfilePic,
            {
              profile_image: base64String,
            },
            {
              headers: {
                Authorization:
                  "Bearer " + localStorage.getItem("encore-auth-token"),
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.status === "failure") {
              toast.error(res.data.message, { toastId: "profileLoadingFail" });
              setLoading(false);
            }
            if (res.data.status === "success") {
              toast.success(res.data.message, {
                toastId: "profileLoadingSuccess",
              });
              userProfilePic
                ? setProfilePicture(
                    `${res.data.data.userProfilePic.substring(1)}`
                  )
                : setProfilePicture(profilePic);
              axios
                .get(CONFIG.getUserInfo, {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("encore-auth-token"),
                    "Content-Type": "application/json",
                  },
                })
                .then((res) => {
                  if (res.data.status === "success") {
                    dispatch(getUserInfoAction(res.data));
                  }
                })
                .catch((err) => {
                  toast.error(err.response?.data?.message, {
                    toastId: "profileLoadingCatchError",
                  });
                  setShowLoader(false);
                  logOut(dispatch(logout()), navigate("/login"));
                });

              dispatch(updateProfilePic(res.data));
              setLoading(false);
            }
          })
          .catch((err) => {
            toast.error(err.response?.data?.message, {
              toastId: "fromCatchError",
            });
            setLoading(false);
          });
      };
    })(fileName);

    // Read in the image file as a data URL.
    reader.readAsBinaryString(fileName);
  };

  // Update Profile imformation
  const updateProfile = (e) => {
    e.preventDefault();
    if (!showOtp) {
      const reqData = {
        userContact:
          localStorage.getItem("encore-country-code") +
          document.getElementsByName("user-contact")[0].value,
      };
      axios
        .post(CONFIG.updateMobileNumber, reqData, {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("encore-auth-token"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "failure") {
            document.getElementsByName("user-contact")[0].focus;
            setContactError(true);
            toast.error(
              <span
                dangerouslySetInnerHTML={{ __html: res.data.message }}
              ></span>,
              { toastId: "profileContactError" }
            );
            setLoading(false);
          }
          if (res.data.status === "success") {
            setContactError(false);
            setShowOtp(true);
            setotp("");
            setVerifyOtpToken(res.data.data.access_token);
            toast.success(
              <span
                dangerouslySetInnerHTML={{ __html: res.data.message }}
              ></span>,
              { toastId: "profileContactSuccess" }
            );
            setLoading(false);
          }
        })
        .catch((err) => {
          setContactError(true);
          document.getElementsByName("user-contact")[0].focus;
          toast.error(err.response?.data?.message, {
            toastId: "profileContactCatch",
          });
          setLoading(false);
        });
    } else {
      if (otp?.length < 4) {
        seterror(true);
      } else {
        seterror(false);
        setLoading(true);
        axios
          .post(
            CONFIG.verifyOtptoUpdateNumber,
            {
              userContact:
                localStorage.getItem("encore-country-code") +
                document.getElementsByName("user-contact")[0].value,
              userOtp: otp,
            },
            {
              headers: {
                Authorization:
                  "Bearer " + localStorage.getItem("encore-auth-token"),
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.status === "failure") {
              toast.error(
                <span
                  dangerouslySetInnerHTML={{ __html: res.data.message }}
                ></span>,
                { toastId: "otpError" }
              );
              seterror(true);
              setLoading(false);
            }
            if (res.data.status === "success") {
              toast.success(
                <span
                  dangerouslySetInnerHTML={{ __html: res.data.message }}
                ></span>,
                { toastId: "otpSuccess" }
              );
              setShowOtp(false);
              seterror(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            toast.error(err.response?.data?.message, {
              toastId: "otpCatchError",
            });
            setLoading(false);
          });
      }
    }
  };

  // Update OTP state value on change
  const handleChange = (otpNumber) => {
    setotp(otpNumber);
  };

  // Delete User
  const approveDelete = (approve) => {
    if (approve == "yes") {
      deleteUser()
        .then((res) => {
          if (res.data.status === "failure") {
            toast.error(res.data.message);
            document.querySelector(".Toastify").classList.remove("overlay");
            setShowLoader(false);
          }
          if (res.data.status === "success") {
            toast.update(deleteUserId.current, {
              render: res.data.message,
              type: toast.TYPE.SUCCESS,
              autoClose: 3000,
              closeButton: false,
            });
            document.querySelector(".Toastify").classList.remove("overlay");
            setShowLoader(false);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
            // logOut(dispatch(logout()), navigate("/login"));
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
          setShowLoader(false);
          document.querySelector(".Toastify").classList.remove("overlay");
        });
    } else if (approve == "confirm") {
      toast.update(deleteUserId.current, {
        render: () => {
          return (
            <div className="warning-toast">
              <h5 className="mb-1">Delete Account</h5>
              <p className="mb-2">
                Are you sure you want to delete your account?
              </p>
              <p className="mb-2">
                This will permanently erase your account from EncoreHealthCare
              </p>
              <button
                className="btn danger sm mr-1"
                onClick={() => approveDelete("yes")}
              >
                Delete
              </button>
              <button
                className="btn outline sm"
                onClick={() => approveDelete("cancel")}
              >
                cancel
              </button>
            </div>
          );
        },
      });
      setDeleteCheck(true);
    } else {
      toast.dismiss(deleteUserId.current);
      document.querySelector(".Toastify").classList.remove("overlay");
      setShowLoader(false);
    }
  };

  // Delete confirmation popup
  const deleteCurrentUser = () => {
    setShowLoader(true);
    deleteUserId.current = toast(
      <div className="warning-toast">
        <h5 className="mb-1">Delete Account</h5>
        <p className="mb-2">Are you sure you want to delete your account?</p>
        {deleteCheck && (
          <p className="mb-2">
            This will permanently erase your account from EncoreHealthCare
          </p>
        )}
        <button
          className="btn danger sm mr-1"
          onClick={() => approveDelete("confirm")}
        >
          Delete
        </button>
        <button
          className="btn outline sm"
          onClick={() => approveDelete("cancel")}
        >
          cancel
        </button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        onOpen: () => {
          document.querySelector(".Toastify").classList.add("overlay");
        },
        onclose: () => {
          document.querySelector(".Toastify").classList.remove("overlay");
        },
      }
    );
  };

  document.title = "Encore | My Profile";

  return (
    <main className="page">
      <div className="page-header">
        <div className="page-header-left">
          <h3 className="mb-0 d-flex align-center text-primary">My Profile</h3>
        </div>
      </div>
      <div className="row align-start">
        <div className="content full">
          <div className="content-box">
            <Loader show={showLoader} />
            {/* <h5 className="content-title">My Profile</h5> */}
            <form onSubmit={updateProfile}>
              <div className="row">
                <div className="col-full mb-3">
                  <div className="custome-file">
                    <div className="custome-file-image">
                      {/* <img src={profilePic} alt="" /> */}
                      <img src={ProfilePicture} alt={state.fullName} />
                      {/* <img src="https://encoreapi.demo.brainvire.dev/images/users/1668165374700.png" alt="" /> */}
                    </div>
                    <div className="custome-file-input">
                      <input type="file" onChange={uploadProfilePic} />
                      <i className="icon-camera"></i>
                    </div>
                  </div>
                </div>
                <div className="col-full fullname-spacing-left ">
                  <div className="w-25">
                    <InputGroup
                      label="Full Name"
                      className="spacing-left"
                      placeholder="Full Name"
                      type="text"
                      value={state.fullName}
                      disabled={true}
                    />
                  </div>
                </div>
                {/* <div className="col-half">
						<InputGroup label="SSN" placeholder="XXX123456" type="number" value={state.userSSN} disabled={true} />
					</div> */}
                <div className="col-full mobile-spacing-left">
                  <div className="w-25">
                    <InputGroup
                      label="Contact Number"
                      placeholder="Contact Number"
                      value={state.userContact}
                      type="number"
                      name="user-contact"
                      error={contactError}
                      setError={setContactError}
                      validate="mobile"
                    />
                  </div>
                </div>
                {showOtp && (
                  <div className="col-full profile-otp">
                    <div className="form-group">
                      <label className="form-label">Verify OTP</label>
                      <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                        containerStyle="verification-digits"
                        inputStyle="otp-digit"
                        shouldAutoFocus="true"
                        hasErrored={error}
                        errorStyle="error"
                        isInputNum="true"
                        placeholder="0000"
                      />
                    </div>
                  </div>
                )}
                <div className="col-full mt-2 mb-3">
                  <button
                    type="submit"
                    className={`btn ${loading ? "loading" : ""}`}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
            <div className="mt-3">
              <span
                className="text-danger cursor-pointer"
                onClick={() => deleteCurrentUser()}
              >
                <u>Delete Account</u>
              </span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;
