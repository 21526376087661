import React from "react";
import "../assets/SCSS/components/_no-data.scss";

// Common Layout for No Data
const NoData = () => {
	return (
		<>
			<div className="no-data">
				<i className="icon-info mb-3"></i>
				<h3 className="text-center">No Data</h3>
			</div>
		</>
	);
};

export default NoData;
