import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { aboutUs } from "../axios/apicall";
import Loader from "../components/Loader";

// About Us page
const AboutUs = () => {
	let navigate = useNavigate();
	const [aboutData, setAboutData] = useState({});
	const [showLoader, setShowLoader] = useState(false);
	
	const aboutUsContent = () => {
		setShowLoader(true);
		aboutUs()
			.then((about) => {
				if (about.data.status === "success") {
					setAboutData(about.data.data);
					setShowLoader(false);
				} else {
					toast.error(about.data.message, { toastId: "aboutDataFail" });
					setShowLoader(false);
				}
			})
			.catch((err) => console.log(err));
		};

	useEffect(() => {
		aboutUsContent();
	}, []);
	
	document.title = "Encore | About Us";
	
	return (
		<main className="page">
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 text-primary d-flex align-center">
						<i className="cursor-pointer text-body mr-1 icon-back" onClick={() => navigate(-1)}></i>
						About Us
					</h3>
				</div>
			</div>
			<div className="row align-start">
				<div className="content full">
					<div className="content-box">
						<Loader show={showLoader} />
						{aboutData?.name && (
							<>
								<div className="user-card mb-3">
									<div className="user-image">
										<img src={aboutData.profilePic} alt={aboutData.name} />
									</div>
									<div className="user-detail">
										<h5 className="text-primary mb-1">{aboutData.name}</h5>
										<span>{aboutData.profession}</span>
									</div>
								</div>
								<div dangerouslySetInnerHTML={{ __html: aboutData.description }}></div>
							</>
						)}
					</div>
				</div>
			</div>
		</main>
	);
};

export default AboutUs;
