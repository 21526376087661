import React, { useState } from "react";
import { Link } from "react-router-dom";
import { InputGroup } from "../../components/Form";
import "../../assets/SCSS/pages/auth.scss";
import pageBg from "../../assets/images/auth-bg.webp";
import mobileBg from "../../assets/images/auth-bg-mobile.webp";
import logo from "../../assets/images/encore.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CONFIG from "../../config/api.config";
import { useDispatch } from "react-redux";
import { adminForgotVerify, forgotVerify, forgotVerifyToken, signUpVerified } from "../../features/login/AuthSlice";

const AdminForgotPassword = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [emailError, setEmailError] = useState(false);
	const [loading, setLoading] = useState(false);

	const checkEmail = (e) => {
		e.preventDefault();
		const reqData = {
			email: document.getElementsByName("admin-email")[0].value,
		};
		if (document.getElementsByName("admin-email")[0].value == "") {
			toast.error("Email Required!");
			setEmailError(true);
			return;
		} else {
			setLoading(true);
			axios
				.post(CONFIG.adminForgotPassword, reqData)
				.then((res) => {
					if (res.data.status === "failure") {
						document.getElementsByName("admin-email")[0].focus;
						setEmailError(true);
						toast.error(res.data.message);
						setLoading(false);
					}
					if (res.data.status === "success") {
						setEmailError(false);
						toast.success(res.data.message);
						dispatch(adminForgotVerify(res.data.data));
						navigate("/admin/verify-otp");
						setLoading(false);
					}
				})
				.catch((err) => {
					setEmailError(true);
					document.getElementsByName("admin-email")[0].focus;
					toast.error(err.response?.data?.message);
					setLoading(false);
				});
		}
	};

	document.title = "Encore | Forgot Password";

	return (
		<div className="auth-page-wrapper">
			<picture>
				<source media="(min-width:768px)" srcSet={pageBg} />
				<img src={mobileBg} alt="auth-page" height="970px" width="1920px" className="bg-img" />
			</picture>
			<div className="logo">
				<img src={logo} alt="Encore" height="40px" width="171px" />
			</div>
			<div className="auth-card ml-auto">
				<h4 className="card-title text-center">Forgot Password</h4>
				<form onSubmit={checkEmail}>
					<InputGroup label="Email" icon="email" placeholder="Email" type="email" error={emailError} name="admin-email" validate="email" setError={setEmailError} />
					<button type="submit" className={`btn btn-primary full ${loading ? "loading" : ""}`}>
						Submit
					</button>
				</form>
				<div className="text-center mt-3">
					<Link to={"/admin"}>Back</Link>
				</div>
			</div>
		</div>
	);
};

export default AdminForgotPassword;
