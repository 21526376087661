import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "../assets/SCSS/pages/profile.scss";
import CONFIG from "../config/api.config"
// Terms Of Use
const TermsOfUse = () => {
	const [Terms, setTerms] = useState("");
	axios
		.post(
			CONFIG.privacyPolicy,
			{
				key: "term_condition",
			},
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem("encore-auth-token"),
					"Content-Type": "application/json",
				},
			}
		)
		.then((res) => {
			setTerms(res.data.data.content);
		})
		.catch((err) => {
			toast.error(err.response?.data?.message, { toastId: "TermsOfUseCatchError" });
		});
	document.title = "Encore | Terms Of Use";
	return (
		<main className="page">
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 d-flex align-center">Terms of Use</h3>
				</div>
			</div>
			<div className="row align-start">
				<div className="content full">
					<div className="content-box">
						<p>{Terms}</p>
					</div>
				</div>
			</div>
		</main>
	);
};

export default TermsOfUse;
