import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { listOfAdditionalFacility } from "../axios/apicall";
import Loader from "../components/Loader";
import "../assets/SCSS/pages/additional-facilities.scss"
import { toast } from "react-toastify";


// Additional Facility Page
const AdditionalFacility = () => {
	const [showLoader, setShowLoader] = useState(false);
	const [facility, setFacility] = useState();
	const location = useLocation();

	const additionalFacility = () => {
		setShowLoader(true);
		listOfAdditionalFacility()
			.then((res) => {
				if (res.data.status === "success") {
					setFacility(res.data.data);
					setShowLoader(false);
				} else {
					toast.error(res.data.message, { toastId: "additionalFacilitiesFail" });
					setShowLoader(false);
				}
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		additionalFacility();
	}, []);
	
	document.title = "Encore | Additional Benefits";

	return (
		<main className="page">
			<div className="page-header">
				<div className="page-header-left">
					<h3 className="mb-0 text-primary d-flex align-center">
						{/* <i className="cursor-pointer text-body mr-1 icon-back" onClick={() => navigate(-1)}></i> */}
						Additional Benefits
					</h3>
				</div>
			</div>
			<div className="row align-start">
				<div className="content full">
					<div className="content-box">
						<Loader show={showLoader} />
						<div className="facility-list-wrapper">
							<div className="facility-list">
								{/* Render Facility list */}
								{facility?.map((item, index) => {
									return (
										<Link key={item.id} to={`/additional-benefits/${item.id}`} className={`facility-list-item ${location.pathname == "/additional-benefits/" + item.id ? `active` : ""}`}>
											{item.title}
										</Link>
									);
								})}
							</div>
							{/* Outlet for desplay Facility data */}
							<div className="facility-content">
								<Outlet />
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default AdditionalFacility;
