import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getChatUserListing, getUserChatList, terminateChatSession } from "../../axios/apicall";
import userDefaultPic from "../../assets/images/user-profile.png";
import CONFIG from "../../config/api.config";
import io from 'socket.io-client';
import NotificationSound from "../../assets/audio/encore-notification.mp3";
import { CHAT_TYPE, SENDER_ID } from "./Chat";
import moment from "moment/moment";

const ChatListing = () => {
	const [chatMessage, setChatMessage] = useState("");
	const [messageList, setMessageList] = useState([]);
	const [msgPage, setMsgPage] = useState(0);
	const [currentUser, setCurrentUser] = useState();
	const [totalpage, setTotalpage] = useState(0);
	const [totalChatsPerPage, setTotalChatsPerPage] = useState(0);

	const { id } = useParams();
	const bottomRef = useRef(null);
	const NotificationSoundref = useRef(null);
	const [updatedSocket, setSocket] = useState('');
	const groupName = `${SENDER_ID}-${id}`;
	const groupMember = [SENDER_ID, id];

	// Get current user chat
	const getCurrentUserChat = () => {
		getUserChatList(id, groupName, msgPage, true).then((res) => {
			setTotalpage(res.data?.data?.totalpage)
			setTotalChatsPerPage(res.data?.data?.perpage)
			setMessageList(res.data?.data?.data)
			setTimeout(() => {
				bottomRef.current?.scrollIntoView({ behavior: "smooth" });
			}, 100);
		}).catch((err) => { console.log(err) })
	}
	const chatUserListing = () => {
		getChatUserListing().then((res) => {
			setMsgPage(0)
			const userData = res.data.data.data;
			setCurrentUser(userData?.filter((user) => user.userId == id));
		}).catch((err) => {
			toast(err.response?.data?.message, { toastId: "chatUserListError" });
		});
	}
	useEffect(() => {
		document.querySelector('.chat-sidebar').classList.add("hide")
		if (!id) {
			return;
		}
		setMsgPage(0)
		setMessageList([])
		chatUserListing()
		getCurrentUserChat()
		// configure socket
		const userDetail = {
			"groupName": groupName,				//`1-${id}`,
			"groupMembers": groupMember,		//["1", `${id}`],
			"senderId": SENDER_ID,
			"type": CHAT_TYPE						//"onetoone"
		}
		let socket = io(CONFIG.socketURL, { query: `senderId=${SENDER_ID}&groupName=${groupName}` });
		socket.on('connect', () => {
			setSocket(socket);
			if (userDetail) {
				socket.emit("join", userDetail)
			}
		})
		socket.on('received', (data) => {
			getUserChatList(id, groupName, msgPage, true).then((res) => {
				setMessageList(res.data?.data?.data)
				setTimeout(() => {
					bottomRef.current?.scrollIntoView();
				}, 10);
			}).catch((err) => { console.log(err?.response) })
		})
		socket.on("notifyUnreadGlobal", (data) => {
			chatUserListing();
		})
		return () => {
			socket.disconnect();
		}

	}, [id]);

	// End User Session
	const endUserSession = () => {
		terminateChatSession(groupName, SENDER_ID).then((res) => {
			if (res.data.status === "failure") {
				toast.error(res.data.message, { toastId: "endUserSessionError" })
			}
			if (res.data.status === "success") {
				toast.success(res.data.message, { toastId: "endUserSessionSuccess" })
				updatedSocket.emit('terminateSession', id.toString(), [], CHAT_TYPE, groupName)
			}
		}).catch((err) => { console.log(err?.response) })
	}

	// Send Chat Message
	const sendChatMessage = (e) => {
		e.preventDefault();
		updatedSocket.emit("chat message", chatMessage, 1, [], CHAT_TYPE, groupName)
		setMsgPage(0)
		getUserChatList(id, groupName, msgPage, true).then((res) => {
			setMessageList(res.data?.data?.data)
			bottomRef.current?.scrollIntoView();
		}).catch((err) => { console.log(err?.response) })
		setChatMessage("");
	}

	// Chat load more data on scroll to top
	const loadMoreChats = (e) => {
		if (e.currentTarget.scrollTop === 0) {
			if (msgPage < totalpage) {
				getUserChatList(id, groupName, msgPage + 1, true).then((res) => {
					res.data?.data?.data.map((newData) => {
						messageList?.length && messageList.unshift(newData);
					})
				}).catch((err) => { console.log(err?.response) })
				setMsgPage(msgPage + 1)
				setTimeout(() => {
					const firstOldList = document.querySelectorAll('.message-date-group .chat-message-item')
					if (firstOldList.length > totalChatsPerPage) {
						firstOldList[totalChatsPerPage + 1].scrollIntoView()
					}
				}, 10);
			}
		}
	}

	// Convert runtime date
	// const convetDate = (date) => {
	// 	const msgDate = new Date(date)
	// 	let msgHours = msgDate.getHours();
	// 	let msgMinutes = msgDate.getMinutes();
	// 	let msgAmPm = msgHours >= 12 ? 'PM' : 'AM';
	// 	msgHours = msgHours % 12;
	// 	msgHours = msgHours ? msgHours : 12; // the hour '0' should be '12'
	// 	msgHours = msgHours < 10 ? '0' + msgHours : msgHours;
	// 	msgMinutes = msgMinutes < 10 ? '0' + msgMinutes : msgMinutes;
	// 	let msgStrTime = msgHours + ':' + msgMinutes + ' ' + msgAmPm;

	// 	return msgStrTime;
	// }

	return (
		<div className="chat-window">
			{/* Chat Header */}
			<div className="chat-window-header">
				<div className="chat-user">
					<button className="chat-back-btn" onClick={() => { document.querySelector('.chat-sidebar').classList.remove("hide") }}><i className="icon-back"></i></button>
					<div className="user-profile-pic">
						{currentUser && <img src={currentUser[0]?.profileImage ? currentUser[0]?.profileImage : userDefaultPic} alt="user-name" />}
						{/* <span className={`user-status ${currentUser[0]?.online ? "online" : ""}`}></span> */}
					</div>
					<div className="chat-user-name">
						{currentUser && currentUser[0]?.userName}
						{/* <small>{currentUser[0]?.online ? "online" : "offline"}</small> */}
					</div>
				</div>
				{/* <div className="chat-header-menu">
					<i className="icon-menu"></i>
				</div> */}
			</div>
			{/* Chat Header End*/}
			{/* Chat Listing */}
			<div className="chat-window-listing" onScroll={(e) => loadMoreChats(e)}>
				{
					messageList &&
					messageList?.map((msg) => {
						return <div className="message-date-group" key={msg.date + Math.random()}>
							<div className="chat-date">
								<span>{msg.date}</span>
							</div>
							<div className="chat-message-listing">
								{
									msg.message?.map((msgContent) => {
										return <div className={`chat-message-item ${msgContent.senderId == SENDER_ID ? "self" : ""}`} key={msgContent._id}>
											{msgContent.senderId != SENDER_ID && currentUser ? <div className="chat-message-profile">
												{/* <img src={userDefaultPic} alt="Profile" /> */}
												<img src={currentUser[0]?.profileImage ? currentUser[0]?.profileImage : userDefaultPic} />
											</div> : ""}
											<div className="chat-message">
												<div className="chat-message-detail">
													{currentUser && msgContent.senderId != SENDER_ID && currentUser[0]?.userName}
													<small className="chat-message-time">
														{
															moment(msgContent.createdAt).utcOffset(-360).format('hh:mm A')
														}
													</small>
													{/* <small className="chat-message-time">{new Date(msgContent.createdAt).substr(11, 5)}</small> */}
												</div>
												<div className="chat-message-content">{msgContent.message}</div>
											</div>
										</div>
									})
								}
							</div>
						</div>
					})
				}
				<div ref={bottomRef}></div>
			</div>
			{/* Chat Listing End */}
			{/* Chat Footer */}
			<div className="chat-window-footer">
				<div className="chat-footer-profile">
					{currentUser && <img src={currentUser[0]?.profileImage ? currentUser[0]?.profileImage : userDefaultPic} alt="profile pic" />}
				</div>
				<form className="chat-footer-form" onSubmit={(e) => sendChatMessage(e)}>
					<input
						type="text"
						placeholder="Type a message"
						value={chatMessage}
						onChange={(e) => {
							setChatMessage(e.target.value);
						}}
					/>
					<button
						className="text-primary" type="submit">
						<i className="icon-send"></i>
					</button>
				</form>
				<button className="btn" onClick={() => endUserSession()}>End the Session</button>
			</div>
			{/* Chat Footer End */}
			<audio ref={NotificationSoundref} src={NotificationSound} style={{ display: "none" }} />
		</div>
	);
};

export default React.memo(ChatListing);
