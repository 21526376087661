import React, { useEffect, useState } from "react";
import { downloadSampleFile, importFile } from "../axios/apicall";
import Loader from "../components/Loader";
import "../assets/SCSS/pages/employees.scss";
import "../assets/SCSS/pages/imported-employees.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PopupImportFile from "./PopupImportFile";

const ImportedEmployees = () => {
  const Navigate = useNavigate();
  const [importedemployees, setImportedEmployees] = useState([]);
  const [employeeCount, setEmployeeCount] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [search, setSearch] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileInput, setShowFileInput] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [modifiedData, setModifiedData] = useState(false);
  const [ignoredColumns, setIgnoredColumns] = useState([]);

  const [filter, setFilter] = useState({
    userStatus: null,
    search: null,
    page: 1,
  });

  useEffect(() => {
    setShowLoader(true);
      const importedEmployees = JSON.parse(localStorage.getItem('imported-employees'));
      const ignoredEmployees = JSON.parse(localStorage.getItem('imported-ignored-records'));

        if (importedEmployees) {
          setImportedEmployees(importedEmployees);
          setEmployeeCount(importedEmployees.length);
          setShowLoader(false);
        }
        if (ignoredEmployees) {
          setIgnoredColumns(ignoredEmployees);
          setShowLoader(false);
        }
  }, [filter, modifiedData]);

  document.title = "Imported Employees";

  const options = [
    { value: null, label: "All" },
    { value: "1", label: "Active" },
    { value: "0", label: "In Active" },
  ];


  const ShowPopupHandler = () =>{
    setShowPopup(true);
  }
  const closePopUpHandler = () =>{
     setShowPopup(false);
    setShowLoader(false);
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (selectedFile) {  

      const fileExtension = selectedFile.name.split('.').pop();

      if (fileExtension !== 'csv') {
        toast.error("Unsupported file format.");
        return false;
      }
      if (selectedFile.size > 100 * 1024 * 1024) { // 100 MB in bytes
        toast.error("File size exceeds the maximum allowed limit (100 MB).");
        return false;
      }
      setShowLoader(true);    
      const fileUrl = URL.createObjectURL(selectedFile);
      setSelectedFileUrl(fileUrl);

      const form_data = new FormData();
      form_data.append('userData', selectedFile);

       importFile(form_data)
       .then((res) => {
        setSelectedFile(null)
        if (res.data.status_code === 400) {
          toast.error(res.data.message);
          localStorage.setItem('error-imported-employees', JSON.stringify(res.data.data));
          setShowPopup(false);
          setShowLoader(false);
          Navigate(`/employees/error_imported`);
        }
        if(res.data.status_code === 200){
          toast.success(res.data.message);
          setShowLoader(false);
          localStorage.setItem('imported-employees', JSON.stringify(res.data.data.importedRecords));
          localStorage.setItem('imported-ignored-records', JSON.stringify(res.data.data.ignoredRecords));
          setModifiedData(!modifiedData);
          setShowPopup(false);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setShowLoader(false);
      });
    }
  };
  const DownloadFileHandler = () =>{
     downloadSampleFile()
      .then((res) => {
        if(res.status === 200){
           const sampleFileData = res.data; // This should contain your sample file data
          // Create a Blob object from the file data
          const blob = new Blob([sampleFileData], { type: "text/csv;charset=utf-8" });
          // Create a URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
        // Create an anchor element
          const anchor = document.createElement("a");
          anchor.href = blobUrl;
          anchor.download = "sample-file";
        // Programmatically click the anchor to trigger the download
          anchor.click();
          // Clean up the URL created for the Blob
          URL.revokeObjectURL(blobUrl);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setShowLoader(false);
      });
  }
  return (<>
    <main className="page">
      <div className="page-header page-header-imported">
        <div className="page-header-left">
          <h3 className="mb-0 d-flex align-center text-primary"> Ignored Records</h3>
        </div>
        <div className="form-filter-group">
          <div className="upload-sheet-btn">
            <button onClick={ShowPopupHandler}>Upload Employee Sheet</button>
          </div>
        </div>
      </div>
      <div className="row align-start">
        <div className="content full">
          <Loader show={showLoader} />

          <div className="table-wrapper">
            <table className="table table-hover ignored-records-table">
              <thead>
                <tr>
                  <th className="bg-primary text-white">User Name</th>
                  <th className="bg-primary text-white">Email</th>
                  <th className="bg-primary text-white">Phone No.</th>
                  <th className="bg-primary text-white">Phone No. 2</th>
                  <th className="bg-primary text-white">User Plan</th>
                  <th className="bg-primary text-white">Error</th>
                </tr>
              </thead>
              <tbody>
                {ignoredColumns && ignoredColumns.length > 0 ? (
                  ignoredColumns?.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td>{data.record?.coveredPerson}</td>
                        <td>{data.record?.email ? data.record?.email : "-"}</td>
                        <td>{data.record?.phone1 ? data.record?.phone1 : "-"}</td>
                        <td>
                          {data.record?.phone2 ? data.record?.phone2 : '-'}
                        </td>
                        <td>{data.record.planName ? data.record.planName : " - "}</td>
                        <td>{data?.message}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <h6 style={{ textAlign: "center" }} className="no-data-found">No Data</h6>
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>
            

            <div className="page-header-left  mt-3">
              <h3 className="mb-2 d-flex align-center text-primary">Imported Employees</h3>
            </div>
          
            <table className="table table-hover imported-records-table">
              <thead>
                <tr>
                  <th className="bg-primary text-white">User Name</th>
                  <th className="bg-primary text-white">Email</th>
                  <th className="bg-primary text-white">Phone No.</th>
                  <th className="bg-primary text-white">Phone No. 2</th>
                  <th className="bg-primary text-white">User Plan</th>
                </tr>
              </thead>
              <tbody>
                {
                  importedemployees && importedemployees.length > 0 ? (
                    importedemployees?.map((data, key) => {
                      return (
                        <tr key={key}>
                          <td>{`${data?.coveredPerson}`}</td>
                          <td>{data?.email}</td>
                          <td>{data?.phone1 ? data?.phone1 : "-"}</td>
                          <td>
                            {data?.phone2 ? data.phone2 : "-"}
                          </td>
                          <td>{data?.planName ? data?.planName : " - "}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <h6 style={{ textAlign: "center" }} className="no-data-found">No Data</h6>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </main>

    <PopupImportFile
      showFileInput={showFileInput}
      showPopup={showPopup}
      closePopUpHandler={closePopUpHandler}
      handleFileChange={handleFileChange}
      handleFileUpload={handleFileUpload}
      selectedFile={selectedFile}
      setShowFileInput={setShowFileInput}
      DownloadFileHandler={DownloadFileHandler}
      showLoader={showLoader}
    />
    </>
  );
};

export default ImportedEmployees;
